import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Container, Form, Row, Col, Spinner, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import ServerActions from "../../API/API";
import "./Registration.css";
import { useEffect } from "react";
import { withRouter } from "../../Utils/withRouter";
import CoursesAPI from "../../API/Courses";

function Registration({ navigate, ...props }) {
  const [proceedModal, setProcessModal] = useState(false);
  const [whatsAppData, setWhatsAppData] = useState({});
  const [register, setRegister] = useState({
    full_name: "",
    email: "",
    course: {},
    phone: "",
    whats_app: "",
    gender: "",
    country: "",
    city: "",
    address: "",
  });

  const [allCourses, setAllCourses] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const getAllCourses = async ()=>{
      await CoursesAPI.get()
      .then(res=>{
        if(res && res.data && Array.isArray(res.data)){
          setAllCourses(res.data);
        }
      })
    }
    getAllCourses();
  }, []);

  const handleInputs = (e) => {
    const name = e.target.name,
      value = e.target.value;
    if (name === "course") {
      setRegister({ ...register, [name]: allCourses[value] });
    } else {
      setRegister({ ...register, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() && !isProcessing) {
      let data = { ...register };
      data.course = register.course?.name;
      setIsProcessing(true);
      await ServerActions.post_register(data)
        .then((res) => {
          if (res && res.data === "success") {
            if (register.course.is_paid) {
              setWhatsAppData(data);
              toggleProceedModal();
            } else {
              openWhats(data);
            }
          } else {
            toast.dark(res.data);
          }
        })
        .catch((err) => {
          toast.error("Unknown error occur");
          console.log(err);
        });

      setIsProcessing(false);
    }
  };

  const toggleProceedModal = () => {
    setProcessModal(!proceedModal);
  };

  const openWhats = (data) => {
    let { full_name, email, course, phone, whats_app, country, city, address } =
      data;
    let url =
      `https://api.whatsapp.com/send?phone=923205486567&&text=*Asslam O Alaikum!*%0a` +
      `I am *${full_name}*. %0a` +
      `I want to register myself against *${course}* course of DTD.%0a` +
      `My other details are:%0a` +
      `Email: *${email}*,%0a` +
      `Phone: *${phone}*,%0a` +
      `what app: *${whats_app}*,%0a` +
      `country: *${country}*,%0a` +
      `city: *${city}*,%0a` +
      `address: *${address}*,%0a`;
    window.open(url);
  };

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <Modal show={proceedModal} onHide={toggleProceedModal} centered>
        <Modal.Header>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you are pakistan national then your fee for Quran's Language
          Program is 1500 PKR per month. For international students it's 4000
          PKR per month.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={toggleProceedModal}>
            Cancel
          </Button>
          &nbsp;
          <Button variant="primary" onClick={() => openWhats(whatsAppData)}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="registration_container shadow mb-4">
        <h1 className="text-primary text-center ">Registration</h1>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  name="full_name"
                  type="text"
                  onChange={handleInputs}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  onChange={handleInputs}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Select Course</Form.Label>
                <Form.Select name="course" onChange={handleInputs} required>
                  <option value=""></option>
                  {allCourses.map(
                    (el, index) =>
                      el.isAdminssionOpen && (
                        <option key={el._id} value={index}>
                          {el.name}
                        </option>
                      )
                  )}
                </Form.Select>
              </Form.Group>

              <Form.Group className="react_phoneInput">
                <Form.Label>Phone Number</Form.Label>
                <PhoneInput
                  country={"pk"}
                  onChange={(e) =>
                    handleInputs({ target: { value: e, name: "phone" } })
                  }
                  enableSearch={true}
                  inputProps={{ required: true }}
                  placeholder="+92"
                  required
                />
              </Form.Group>

              <Form.Label>Gender</Form.Label>
              <Form.Group>
                <Form.Check
                  inline
                  value="Male"
                  type="radio"
                  name="gender"
                  label="Male"
                  onChange={handleInputs}
                  required
                />
                <Form.Check
                  inline
                  value="Female"
                  type="radio"
                  name="gender"
                  label="Female"
                  onChange={handleInputs}
                  required
                />
                <Form.Check
                  inline
                  value="Other"
                  type="radio"
                  name="gender"
                  label="Other"
                  onChange={handleInputs}
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={6} md={6} sm={12}>
              <Form.Group className="react_phoneInput">
                <Form.Label>Whats App Number</Form.Label>
                <PhoneInput
                  country={"pk"}
                  onChange={(e) =>
                    handleInputs({ target: { value: e, name: "whats_app" } })
                  }
                  enableSearch={true}
                  inputProps={{ required: true }}
                  placeholder="+92"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Select name="country" onChange={handleInputs} required>
                  <option> </option>
                  <option value="national"> National (Pakistan) </option>
                  <option value="international">International (Abroad)</option>
                </Form.Select>
                <Form.Text>
                  {register.country && register.course?.is_paid && (
                    <>
                      <h5>Note:</h5>
                      <p>
                        If you are pakistan national then your fee for
                        {register.course?.name} is 1500 pkr per month. For
                        international students it's 15$ per month.
                      </p>
                    </>
                  )}
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  name="city"
                  type="text"
                  onChange={handleInputs}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  onChange={handleInputs}
                  as="textarea"
                  name="address"
                  type="text"
                  rows={4}
                  style={{ resize: "none" }}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            className="w-100 mt-3 mb-5"
            disabled={isProcessing}
          >
            {isProcessing && (
              <>
                <Spinner animation="border" size="sm" /> &nbsp;
              </>
            )}
            Register
          </Button>
        </Form>
      </Container>
    </>
  );
}

export default withRouter(Registration);
