import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Container } from "react-bootstrap";
import useWindowDimensions from "../../GetWidth";
import female_icon from "../../assets/images/female.jpg";
import male_icon from "../../assets/images/male.jpg";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import "./TSlider.css";
const TSlider = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      <Container fluid className="SkinBg">
        <div className="py-5">
          <h3 className="text-center text-secondary testimonials-h">
            Testimonials
          </h3>
        </div>
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={
            width > 1150 ? 4 : width > 960 ? 3 : width > 750 ? 2 : 1
          }
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          className="swiperContainer pb-3"
        >
          {[
            {
              name: "Sumaiya Imran and Sarim Khan",
              subTitle: "",
              text: `I couldn't find the suitable words to express my gratitude to you because this journey was not only 
                            for me. I especially joined DTD to introduce the beauty of Allah’s kalam to my son so that he can feel 
                            it understand it and implement it from heart. There is no doubt that you are the one who made it 
                            possible for both of us. As a mother, I somehow understand the process, the strategies the hard 
                            work was behind it just to engage us encourage us to learn and understand it by heart, not 
                            forcefully. There are a lot to say but I will just say, In Sha Allah your and yours teams rewards are 
                            saved for the eternal life and you all will get here as well.
                            At last I will just add it that whenever I saw my son doing any activity regarding to DTD, a big smile 
                            came on my face and my heart filled with the gratitude and prayers

                            JAZAKUMALLAH O KHYRN KASEERA WAA AHSANAL JAZA FID DUNIA WAL AKHIRA
                            `,
            },
            {
              name: "Rabbia Javeed",
              subTitle: "",
              text: `It was beautiful journey indeed... Being a student of this Arabic course I found you a wonderful 
                            teacher as well as a true support... I couldn't participate in a way I was supposed to... So many 
                            personal reasons.... The whole team of DTD deserves a huge round of applause for your generous 
                            efforts specially ma'am Zillehuma... All I wish is to be part of this beautiful journey... May Allah 
                            Almighty bless you people with sound health eman and afiyat of both worlds....
                            Stay richly blessed.
                            `,
            },
            {
              name: "Rehana",
              subTitle: "",
              text: `Asslamoalaikum wrwb 
                            Alhmdulillah Alhmdulillah Allah ki madad ky bad yeh sub apki wja sy hi possible hwa k hum yhan tk 
                            pahunchy Alhmdulillah! 
                            Apka way of teaching zabrdast ha Masha Allah hr point hi clear ho jata tha just sunty sunty hi..koi bhi 
                            Cheez ho hm aisy itna achy tareky sy learn nahi kar paty itny sy time main lekin AP ny krwa di or 
                            hmain sb finger tips par yaad hota tha Alhmdulillah
                            DTD team ny bhi buhat achy sy cooperate kia Maa shaa ALLAH Bht acha tha ye safar ALLAH SWT ap 
                            sub team members ko ap sub ki koshishon ka bahtreeen ajar dein ALLAH SWT Ap sub koo apni 
                            Rahmato se apni shaan k mutabiq nawazy
                            
                            JAZAKUMALLAH O KHYRN KASEERA WAA AHSANAL JAZA FID DUNIA WAL AKHIRA
                            `,
            },
            {
              name: "Qurat-ul-Ain.",
              subTitle: "",
              text: `Assalam o Alaikum wr wb!
                            Alhamdulillah, no doubt it was a great journey with not only Ustadha but all the team who managed 
                            to deliver the language of Quran so well. The method of teaching was so good as Ustadha beside 
                            teaching also share different experiences which boost our Eeman. 
                            The efforts put by Ustadha and whole team; recording lectures then converting it into audio form, 
                            sharing in group, all are remarkable.
                            I pray that may Allah put barakah in all their affairs, ease their matters, bless them with khair, 
                            sukoon, aasan Hisab and make their ending on Eeman and Amal.e.Saleh
                            `,
            },
            {
              name: "Shanila",
              subTitle: "",
              text: `The journey of this dream came true Alhamdulillah. It was tremendous effort by ma'am zillehuma. 
                            She did the best for the sake of Allah. The way of teaching was also very smooth and beautiful. I 
                            learnt a lot from her. Arabic is now not for me that, as it was before because I couldn't understand 
                            the words. Now I feel so good when I recite The Qur'an because I can feel it's beauty 
                            Moderators were also very helpful and nice.
                            All I can say is May Allah give the best reward to her and her family for her hard working and care 
                            and took her precious time to us .She was very responsible through out the journey. Jazakillah Khair
                            `,
            },
            {
              name: "Roohi Arshad",
              subTitle: "",
              text: `Assalam o alaikum wa rahmatullahi wa barakatuho. Although I am not a part of this journey since 
                            long due to my health issues..but I would still like to say that my very short journey was an amazing 
                            experience with DTD.
                            Mam Zille Huma is an excellent teacher and her teaching and explaining method is beautiful 
                            Masha'Allah. 
                            DTD is the best opportunity one can avail to learn Quranic Arabic to understand Quran.
                            May Allah bless the entire team of DTD with highest awards in duniya and aakirah.
                            Aameen ya Rabbi
                            `,
            },
            {
              name: "Fizza Asif",
              subTitle: "",
              text: `Mam Zille huma is just an amazing teacher her way of teaching is just awesome... MashaAllah.... itny 
                            pyar se aram se or stories se baat smjhati hain k forn samajh ajaye...or maine seekha teach krny k 
                            liye kitna humble hona prta h...mam jesy motivate krti hain or stories btati hain us se bht zada 
                            interest develop hota hai...🤍🤍or jesy mam ne apni journey b btai Allah ta'ala ne kesy unki help ki is 
                            se bht zada motivation mili...k hm kuch krna chahain to Allah pak rastay khud bna detay hain 
                            Alhamdulillah...or mam itni baar apny teacher Nouman Ali khan ka zikr krti thi to mje b interest 
                            honay lga k unhain sunu.. sunna strt kiya... he is such an amazing muslim speaker MashaAllah...Allah 
                            ta'ala mam ko dtd ki team ko bht sari kamyabi dain.Aameen
                            `,
            },
            {
              name: "Rabia kanwal",
              subTitle: "",
              text: `Alhamdolillah it was the most beautiful journey I have ever experienced. Mam zillehuma is so 
                            motivating, she knows how to keep the students on track. Mam ny humaray dunia or akhrat k rasty 
                            sedhay keya hn. Un ke batain roz mara zindgi main b bare kam ate hn. Her method of teaching is 
                            best. Chezion ko itna asan kr k bayan krti hn k Koi confusion nahi rahte. Admin support is good. DTD
                            is a blessing indeed. May Allah guide us together on the same track soon.
                            `,
            },
            {
              name: "Maimoona Tariq",
              subTitle: "",
              text: `Assalam o alaykum, Alhamdulilah very simple but very effective way of teaching. Alhamdulilah had 
                            an amazing experience of learning. May Allah bless all the team members of DTD and also accept 
                            their hard work and passion to spread the knowledge. Ameen.
                            `,
            },
            {
              name: "Saman Abbas",
              subTitle: "",
              text: `Direction to Divine k sath ye safr Alhamdulilah buhat hi khubsurat rha or buhat hi acha rha...
                            Learning point of view sy isy buht hi effective paya .. accroding to students mentality,difficult 
                            concepts were explained in a very easy manner ., Raty lgany ki bjay concepts ko clear kia jata 
                            hai....Admins bhi support krty hai. 
                            Method of teaching ye un chexo mai sy ek hai jo Direction To Divine ko bakio sy mukhtalif or 
                            effective bnate hai ..Mam explains very nicely ..un ka focus is bat pr zyda hota hai k hmay 'chex 
                            samjh mai a jay' bjay is ky ky sirf yad kr lia jay .✨                            
                            `,
            },
            {
              name: "Yumna Adil",
              subTitle: "",
              text: `My Dream journey was beautiful and Great!!!Alhamdulillah!! And our Dearest Teacher Zille Huma!
                            she is not just our teacher but also a best advisor, loving, caring, her beautiful duas for all of us 
                            extremely supportive teacher ... 
                            Her explanation is just Awesome!!! I didn't find yet a teacher like her in my life....she is my 
                            favourite..... The way she explains I feel like I should not stop here I should continue listening to 
                            her....she not only explains about Arabic but she used to give advice on life, Islamic talks & behaviour 
                            of a human being... how we should to adopt our behaviour in any situation. And Her voice is Masha 
                            Allah Masha Allah!!!! So melodious!!! I love her voice very much..!! She always used to motivates 
                            every student in the group..                          
                            `,
            },
            {
              name: "Mariam",
              subTitle: "",
              text: `I have learned sooo much from this DTD... Alhamdulillah!! The way we were doing assignments and 
                            the due date it was just awesome.... 
                            From 1st to the last day I learned and gained lot of things which I don't know... and the Sarf Families 
                            we used to learn and the past & present tense chart we were by hearting...this was great... and 
                            Every doubt is clarified by mam or admins ... I didn't have any problem from this Journey.... I was 
                            very sad on the topic that our dream journey is postponed to next year.... Jazakumuallahu khairian 
                            kaseera.                          
                            `,
            },
            {
              name: "Maryam urooj",
              subTitle: "",
              text: `Assalam o alaikum! Allhumdulilah my throughout journey was good and comfortable.I am very 
                            satisfied by the teaching method of ma’am zillehuma and performance of admins also.And I am 
                            very enthusiastic to restart it again.
                            Lastly, I want to say that I am very blessed to have this opportunity to know and learn Quran in this 
                            way and blessed to have such a good teacher. Jazakallah khair✨                       
                            `,
            },
          ].map((el, i) => {
            return (
              <SwiperSlide key={"TSlider" + i} className="swiperSlider SkinBg">
                <div className="t_card box_shadow my-3">
                  <div className="t_icon">
                    <FaQuoteLeft size={40} />
                  </div>
                  <div className="mb-5 pe-1">
                    <div className="para">{el.text}</div>
                  </div>
                  <div className="accounts py-3">
                    <div className="p-2 ms-2 account_img">
                      <img
                        src={el?.gender === "male" ? male_icon : female_icon}
                        height="50px"
                        width="50px"
                        alt="directiontodivine testimonials info"
                      />
                    </div>
                    <div className="account_info ps-3 pe-5">
                      <h3 className="text-center account_name">{el.name}</h3>
                      <p> {el.subTitle}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </>
  );
};

export default TSlider;
