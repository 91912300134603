import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import photo from "../../../assets/images/dummydesigner.png";
import GetServerUrl from "../../../API/GetServerUrl";
import { FaGift } from "react-icons/fa";
import { withRouter } from "../../../Utils/withRouter";
import BooksAPI from "../../../API/Books";

const BookDetail = (props) => {
  const [book, setBook] = useState({});

  useEffect(() => {
    const getBook = async ()=>{
      await BooksAPI.getById(props.params.id)
      .then(res=>{
        if(res && res.data){
          setBook(res.data);
        }
      })
      .catch(err=>{

      })
    }

    getBook();
  }, [props.params.id]);

  // const handlePurchase = () => {
  //   const url = `https://api.whatsapp.com/send?phone=923205486567&&text=*Asslam O Alaikum* %0a I want to buy *${book.name}* book.`;
  //   window.open(url);
  // };

  return (
    <>
      <br />
      <br />
      <Container className="p-5">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <Image
              src={book.img ? (GetServerUrl() + "/images/" + book.img) : photo}
              thumbnail
              alt="directiontodivine books image"
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <div className="p-5">
              <h1 className="text-primary">{book.name}</h1>
              <p className="text-primary" dangerouslySetInnerHTML={{__html: book.discription}} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="py-3">
        <div>
          {book.youtubeLinks?.map((el) => {
            return (
              <>
                <div className="w-100 my-3">
                  <iframe
                    src={el}
                    className="w-100"
                    title="Youtube Video"
                    height="400px"
                  />
                </div>
              </>
            );
          })}
        </div>
        <Row>
          <div className="w-100 mt-5">
            <div className="text-center" style={{ color: "var(--bs-orange)" }}>
              <FaGift size={60} />
            </div>
            <div
              className="text-center"
              style={{
                fontSize: "55px",
                color: "var(--bs-primary)",
                marginTop: "-15px",
              }}
            >
              Accept the
              <span style={{ color: "var(--bs-orange)" }}> Gift! </span>
            </div>
            <div
              className="text-center text-muted"
              style={{ marginTop: "-15px" }}
            >
              Fatiha-tul-Kitaab is a gift for the student of Quraan
            </div>
          </div>
          <div className="text-center m-3">
            <Button onClick={() => window.open(GetServerUrl() + "/pdf/" + book.bookUrl)}>
              Download Now
            </Button>
          </div>
          {/* <Col sm={6} xs={12} className="mt-2">
            <Button
              className="w-100"
              onClick={() => window.open(GetServerUrl() + book.book_url)}
            >
              Read First 50 Pages
            </Button>
          </Col>
          <Col sm={6} xs={12} className="mt-2">
            <Button className="w-100" onClick={handlePurchase}>
              Fatiha-tul-Kitaab is a gift for the student of Quraan
            </Button>
            <p className="text-center">Purshased Full Book</p>
          </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default withRouter(BookDetail);
