import React, { useEffect, useState } from 'react'
import TextEditor from "../../Component/TextEditor/TextEditor";
import { Form, Container, Button } from "react-bootstrap";
import { FaPlusCircle, FaTrashAlt, FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router';
import Books from '../../API/Books';
import { toast } from 'react-toastify';



const AddBook = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [course, setCourse] = useState({
        name: "",
        discription:"",
        img: "",
        bookUrl: "",
        youtubeLinks: [],
    });

    useEffect(() => {
        if (params?.id) {
            getCourseById();
        }
    }, [params.id]);

    const getCourseById = async () => {
        await Books.getById(params.id)
            .then(res => {
                if (res && res.data) {
                    setCourse(res.data);                    
                }
            })
    }
    const handleInputs = (e) => {
        const type = e.target.type,
            name = e.target.name,
            value = e.target.value;
        if (type === "file") {
            setCourse({ ...course, [name]: e.target.files[0] });
        } else if (type === "checkbox") {
            setCourse({ ...course, [name]: e.target.checked });
        }  else {
            setCourse({ ...course, [name]: value });
        }
    };

    const addFeature = () => {
        let features = course.youtubeLinks;
        features.push("");
        setCourse({ ...course, youtubeLinks: features });
    };

    const deleteFeature = (index) => {
        let features = course.youtubeLinks.filter((e, i) => i !== index);
        setCourse({ ...course, youtubeLinks: features });
    };

    const handleFeatureInput = (e, index) => {
        let features = course.youtubeLinks;
        features[index] = e.target.value;
        setCourse({ ...course, youtubeLinks: features });
    };

    const handleSave = async (event) => {
        event.preventDefault();
        await Books.post(course)
        .then(res=>{
            if(res && res.data){
                toast.dark(res.data.msg);
            }
        })
        .catch(err=>{
            toast.dark(err?.message || 'Error occured check compulsory fields');
        })
    }

    const handleUpdate = async (event) => {
        event.preventDefault();
        await Books.patch(params.id, course)
        .then(res=>{
            if(res && res.data){
                toast.dark(res.data.msg);
            }
        })
        .catch(err=>{
            toast.dark(err?.message || 'Error occured check compulsory fields');
        })
    }
    return (
        <Container>
            <div className='d-flex justify-content-between'>
                <button className='btn' style={{ fontSize: "20px" }} onClick={() => navigate(-1)}> <FaArrowLeft /> </button>
                <h1 className='text-center text-primary' style={{ flexGrow: 1 }}> Add Book </h1>
            </div>
            <Form onSubmit={params?.id ? handleUpdate : handleSave}>
                <Form.Group>
                    <Form.Label> Select Book Image </Form.Label>
                    <Form.Control
                        name="img"
                        type="text"
                        onChange={handleInputs}
                        value={course.img}
                        required
                    />
                    {/* <Form.Control
                        name="courseImg"
                        type="file"
                        onChange={handleInputs}
                        value={course.file}
                        required
                    /> */}
                </Form.Group>
                <Form.Group>
                    <Form.Label> Name </Form.Label>
                    <Form.Control
                        name="name"
                        type="text"
                        value={course.name}
                        onChange={handleInputs}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Description </Form.Label>
                    <TextEditor
                        name="discription"
                        value={course.discription || ""}
                        handleInputs={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Book Link </Form.Label>
                    <Form.Control
                        name="bookUrl"
                        type="url"
                        value={course.bookUrl}
                        onChange={handleInputs}
                    />
                </Form.Group>
                <div className="pt-2">
                    <div className="text-end w-100">
                        <Button onClick={addFeature}>
                            <FaPlusCircle size={15} /> &nbsp; Add Youtube Links
                        </Button>
                    </div>
                </div>
                <div>
                    {course.youtubeLinks.map((f, index) => {
                        return (
                            <div key={index} className="feature-item my-2 p-1">
                                <div className="editAbleInputContainer">
                                    <input
                                        type="url"
                                        className="editAbleInput"
                                        onChange={(e) => handleFeatureInput(e, index)}
                                        onDoubleClick={(e) => (e.target.readOnly = false)}
                                        onBlurCapture={(e) => (e.target.readOnly = true)}
                                        value={f}
                                    />
                                </div>
                                <div className="ps-1 editDeleteBtnContainer">
                                    <FaTrashAlt
                                        className="icon"
                                        onClick={() => deleteFeature(index)}
                                    />
                                </div>
                                <div className="clear" />
                            </div>
                        );
                    })}
                </div>
                <br />
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="submit" style={{ width: "80%" }}>
                        {params?.id ? <>Update</> : <>Save</>}
                    </Button>
                </div>
            </Form>
        </Container>
    )
}

export default AddBook
