import React from "react";
import AboutInitiative from "../../Component/AboutInitiative/AboutInitiative";
import AyatContainer from "../../Component/Ayat/AyatContainer";
import HomeContainer from "../../Component/HomeContainer/HomeContainer";
import TeamSlider from "../../Component/TeamSlider/TeamSlider";
import TSlider from "../../Component/testimonials/TSlider";
const Home = ()=>{

    return (
        <>
            <HomeContainer/>
            <AboutInitiative/>
            <TeamSlider />
            <TSlider />
            <AyatContainer />
        </>
    )
}

export default Home;