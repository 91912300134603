import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import { toast } from 'react-toastify';
import AdminAPI from '../../API/Admin';

const ManageAccounts = () => {
    const [allAccounts, setAllAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [adminAccount, setAdminAccount] = useState({email: "", password: ""});
    const [openModal, setOpenModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    useEffect(() => {
        getAll();
    }, []);

    const getAll = async () => {
        await AdminAPI.get()
            .then(res => {
                if (res && res.data && Array.isArray(res.data)) {
                    setAllAccounts(res.data);
                }
            })
    }

    const handleInputs = (e) => {
        let name = e.target.name, value = e.target.value;
        setAdminAccount({ ...adminAccount, [name]: value });
    }

    const confirmDelete = (e) => {
        setOpenModal(true);
        setSelectedAccount(e);
    }

    const closeConfirmModal = () => {
        setOpenModal(false);
        setSelectedAccount(null);

    }

    const closeEditModal = () => {
        setEditModal(false);
        setSelectedAccount(null);
        setAdminAccount({email: "", password: ""})
    }

    const handleEdit = (el) => {
        setEditModal(true);
        if(el){
            setSelectedAccount(el);
            setAdminAccount(el);
        }
    }
    const handleDelete = async () => {

        await AdminAPI._delete(selectedAccount._id)
        .then(res => {
            if (res && res.data) {
                toast.dark(res.data.msg);
                closeConfirmModal();
            }
        })
        .catch(err => {
            toast.dark(err.msg);

        })
    }

    const handleSubmit = async () => {

        if(!adminAccount.email){
            toast.error("Please enter email");
            return;
        }
        if(!adminAccount.password){
            toast.error("Please enter password");
            return;
        }

        if (selectedAccount && selectedAccount.email) {
            await AdminAPI.patch(adminAccount._id, adminAccount)
                .then(res => {
                    if (res && res.data) {
                        toast.dark(res.data.msg);
                        closeEditModal();
                    }
                })
                .catch(err => {
                    toast.dark(err.msg);

                })
        } else {
            await AdminAPI.post(adminAccount)
                .then(res => {
                    if (res && res.data) {
                        toast.dark(res.data.msg);
                        closeEditModal();
                    }
                })
                .catch(err => {
                    toast.dark(err.msg);
                })
        }
        getAll();
    }
    return (
        <div>

            <Button className="btn btn-primary" onClick={() => handleEdit(null)}>
                <FaPlusCircle size={15} /> &nbsp; Add
            </Button>
            <Modal show={openModal} onHide={closeConfirmModal} centered>
                <Modal.Header closeButton>Are you sure to delete?</Modal.Header>
                <Modal.Body>
                    <Button variant="danger" onClick={handleDelete}>
                        Yes! delete
                    </Button>
                    &nbsp;
                    <Button
                        onClick={closeConfirmModal}
                        style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
                    >
                        No
                    </Button>
                </Modal.Body>
            </Modal>
            <Modal show={editModal} onHide={closeEditModal} centered>
                <Modal.Header closeButton>Add Account</Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="p-5 p-3">
                            <Form.Group>
                                <Form.Label>
                                    Email
                                </Form.Label>
                                <Form.Control name="email" type="email" onChange={handleInputs} value={adminAccount.email} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Password
                                </Form.Label>
                                <Form.Control name="password" type="password" onChange={handleInputs} value={adminAccount.password} />
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                    &nbsp;
                    <Button
                        onClick={closeEditModal}
                        style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
                    >
                        No
                    </Button>

                </Modal.Footer>
            </Modal>
            <div className='table-responsive-xl'>
                <Table striped hover className="text-center align-middle">
                    <thead>
                        <tr>
                            {[
                                "#",
                                "Email",
                                "Passwords",
                                "Actions",
                            ].map((el, index) => (
                                <th className="text-center px-3" key={index}>
                                    {el}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {allAccounts.map((el, index) => {
                            return (
                                <tr key={index} style={{ fontSize: "14px" }}>
                                    <td>{index + 1}</td>
                                    <td>{el.email}</td>
                                    <td>{el.password}</td>
                                    <td>
                                        <Button onClick={() => handleEdit(el)}>
                                            <FaEdit size={15} />
                                        </Button>
                                        &nbsp;
                                        <Button
                                            className="m-1 text-danger"
                                            to="#"
                                            onClick={() => confirmDelete(el)}
                                        >
                                            <FaTrash size={15} />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default ManageAccounts
