import React from "react";
import { Container} from "react-bootstrap";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

import "./Ayat.css";
const AyatContainer = () => {

    return (
        <>
            <div className="AyatContainer">
                <Container>
                    <div className="trapezoid">
                        <div className="trapzoid_text p-3">
                            <p className="Ayat pt-4 px-2 text-center">
                                <span className="pe-3">
                                    <ImQuotesLeft className="quotes" />
                                </span>
                                    Allah will raise those who have believed among you and those who were given knowledge, by degrees
                                <span className="ps-3">
                                    <ImQuotesRight className="quotes"/>
                                </span>
                            </p>
                            <p className="Ayat text-center">
                                Al- Quran (58:11)
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </>

    );
}

export default AyatContainer;