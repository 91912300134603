import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ServerActions from "../../API/API";

const ManageRegistration = () => {
  const [allRegistrations, setRegistrations] = useState([]);
  const [selectedRegisteration, setSelectedRegisteration] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);

  const openConfirmModal = () => setConfirmModal(true);
  const closeConfirmModal = () => setConfirmModal(false);

  const fetch_allRegistrations = async () => {
    await ServerActions.get_register()
      .then((res) => {
        if (res && res.data !== "error" && res.data?.length) {
          setRegistrations(res.data);
        } else {
          setRegistrations([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetch_allRegistrations();
  }, []);

  const confirmDeleteRegisteration = (index) => {
    setSelectedRegisteration(allRegistrations[index]);
    openConfirmModal();
  };

  const handleDelete = async () => {
    await ServerActions.delete_registeration_by_id(selectedRegisteration._id)
      .then((res) => {
        closeConfirmModal();
        if (res?.data === "success") {
          toast.success("Deleted");
        } else {
          toast.error("unable to delete");
        }
        fetch_allRegistrations();
      })
      .catch((err) => {});
  };

  return (
    <div className="table-responsive-xl px-2 pt-3">
      <Modal show={confirmModal} onHide={closeConfirmModal} centered>
        <Modal.Header closeButton>Are you sure to delete?</Modal.Header>
        <Modal.Body>
          <Button variant="danger" onClick={handleDelete}>
            Yes! delete
          </Button>
          &nbsp;
          <Button
            onClick={closeConfirmModal}
            style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
          >
            No
          </Button>
        </Modal.Body>
      </Modal>

      <Table striped hover className="text-center align-middle">
        <thead>
          <tr>
            {[
              "#",
              "Full Name",
              "Email",
              "Course",
              "Country",
              "Verified",
              "Whats_App",
              "Actions",
            ].map((el, index) => (
              <th className="text-center px-3" key={index}>
                {el}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allRegistrations.map((el, index) => {
            return (
              <tr key={index} style={{ fontSize: "14px" }}>
                <td>{index + 1}</td>
                <td>{el.full_name}</td>
                <td>{el.email}</td>
                <td>{el.course}</td>
                <td>{el.country}</td>
                <td style={{ color: `${el.approved ? "green" : "red"}` }}>
                  {`${el.approved}`}
                </td>
                <td>{el.whats_app}</td>
                <td>
                  <Link to={`/user/${el._id}`}>
                    <FaEdit size={15} />
                  </Link>
                  &nbsp;
                  <Link
                    className="m-1 text-danger"
                    to="#"
                    onClick={() => confirmDeleteRegisteration(index)}
                  >
                    <FaTrash size={15} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageRegistration;
