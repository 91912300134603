const getUser = ()=>{
    try{
        const u = localStorage.getItem("dtd:user");
        if(u){
            return JSON.parse(u);
        } else {
            return null;
        }
    } catch(err){
        console.log("Unable to get user", err);
        return null;
    }
}

const setUser = (user)=>{
    try{
        localStorage.setItem("dtd:user", JSON.stringify(user));
    } catch(err){
        console.log("Unable to set user", err);
        return null;
    }
}

const getToken = ()=>{
    try{
        const t = localStorage.getItem("dtd:token");
        if(t){
            return t;
        } else {
            return null;
        }
    } catch(err){
        console.log("Unable to get token", err);
        return null;
    }
}

const setToken = (token)=>{
    try{
        localStorage.setItem("dtd:token", token);
    } catch(err){
        console.log("Unable to set token", err);
        return null;
    }
}

const clearStorage = ()=>{
    localStorage.clear();
}

const localStorageManager = {
    getToken,
    setToken,
    getUser,
    setUser,
    clearStorage,
}

export default localStorageManager;