import React, { useEffect, useState } from 'react'
import TextEditor from "../../Component/TextEditor/TextEditor";
import { Form, Container, Button } from "react-bootstrap";
import { FaPlusCircle, FaTrashAlt, FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router';
import Courses from '../../API/Courses';
import { toast } from 'react-toastify';



const AddCourse = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [course, setCourse] = useState({
        name: "",
        courseImg: "",
        discription: "",
        explaination: "",
        instructor: "",
        instructorDisc: "",
        instructorImage: "",
        features: "",
        youtubeLinks: [],
        atAGlance: "",
        isPaid: false,
        courseFee: 0,
        courseFeeLine: "",
        isAdminssionOpen: false,
        joinNowYoutubeLink: "",
    });

    useEffect(() => {
        if (params?.id) {
            getCourseById();
        }
    }, [params.id]);

    const getCourseById = async () => {
        await Courses.getById(params.id)
            .then(res => {
                if (res && res.data) {
                    setCourse(res.data);
                }
            })
    }
    const handleInputs = (e) => {
        const type = e.target.type,
            name = e.target.name,
            value = e.target.value;
        if (type === "file") {
            setCourse({ ...course, [name]: e.target.files[0] });
        } else if (type === "checkbox") {
            setCourse({ ...course, [name]: e.target.checked });
        }  else {
            setCourse({ ...course, [name]: value });
        }
    };

    const addFeature = () => {
        let features = course.youtubeLinks;
        features.push("");
        setCourse({ ...course, youtubeLinks: features });
    };

    const deleteFeature = (index) => {
        let features = course.youtubeLinks.filter((e, i) => i !== index);
        setCourse({ ...course, youtubeLinks: features });
    };

    const handleFeatureInput = (e, index) => {
        let features = course.youtubeLinks;
        features[index] = e.target.value;
        setCourse({ ...course, youtubeLinks: features });
    };

    const handleSave = async (event) => {
        event.preventDefault();
        let dataCourse = {...course}
        if(dataCourse.atAGlance && dataCourse.atAGlance === "<p><br></p>"){
            dataCourse.atAGlance = "";
        }
        if(dataCourse.courseFeeLine && dataCourse.courseFeeLine === "<p><br></p>"){
            dataCourse.courseFeeLine = "";
        }
        if(dataCourse.discription && dataCourse.discription === "<p><br></p>"){
            dataCourse.discription = "";
        }
        if(dataCourse.explaination && dataCourse.explaination === "<p><br></p>"){
            dataCourse.explaination = "";
        }
        if(dataCourse.features && dataCourse.features === "<p><br></p>"){
            dataCourse.features = "";
        }
        if(dataCourse.instructorDisc && dataCourse.instructorDisc === "<p><br></p>"){
            dataCourse.instructorDisc = "";
        }
        await Courses.post(dataCourse)
        .then(res=>{
            if(res && res.data){
                toast.dark(res.data.msg);
            }
        })
        .catch(err=>{
            console.log(err);
        })
    }

    const handleUpdate = async (event) => {
        event.preventDefault();
        let dataCourse = {...course}
        if(dataCourse.atAGlance && dataCourse.atAGlance === "<p><br></p>"){
            dataCourse.atAGlance = "";
        }
        if(dataCourse.courseFeeLine && dataCourse.courseFeeLine === "<p><br></p>"){
            dataCourse.courseFeeLine = "";
        }
        if(dataCourse.discription && dataCourse.discription === "<p><br></p>"){
            dataCourse.discription = "";
        }
        if(dataCourse.explaination && dataCourse.explaination === "<p><br></p>"){
            dataCourse.explaination = "";
        }
        if(dataCourse.features && dataCourse.features === "<p><br></p>"){
            dataCourse.features = "";
        }
        if(dataCourse.instructorDisc && dataCourse.instructorDisc === "<p><br></p>"){
            dataCourse.instructorDisc = "";
        }
        await Courses.patch(params.id, dataCourse)
        .then(res=>{
            if(res && res.data){
                toast.dark(res.data.msg);
            }
        })
        .catch(err=>{
            toast.dark(err?.message || 'Error occured check compulsory fields');
        })
    }
    return (
        <Container>
            <div className='d-flex justify-content-between'>
                <button className='btn' style={{ fontSize: "20px" }} onClick={() => navigate(-1)}> <FaArrowLeft /> </button>
                <h1 className='text-center text-primary' style={{ flexGrow: 1 }}> Add Course </h1>
            </div>
            <Form onSubmit={params?.id ? handleUpdate : handleSave}>
                <Form.Group>
                    <Form.Label> Select Course Image </Form.Label>
                    <Form.Control
                        name="courseImg"
                        type="text"
                        onChange={handleInputs}
                        value={course.courseImg}
                        required
                    />
                    {/* <Form.Control
                        name="courseImg"
                        type="file"
                        onChange={handleInputs}
                        value={course.file}
                        required
                    /> */}
                </Form.Group>
                <Form.Group>
                    <Form.Label> Name </Form.Label>
                    <Form.Control
                        name="name"
                        type="text"
                        value={course.name}
                        onChange={handleInputs}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Description </Form.Label>
                    <TextEditor
                        name="discription"
                        value={course.discription || ""}
                        handleInputs={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Explaination </Form.Label>
                    <TextEditor
                        name="explaination"
                        value={course.explaination || ""}
                        handleInputs={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Features </Form.Label>
                    <TextEditor
                        name="features"
                        value={course.features || ""}
                        handleInputs={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> At a Galance </Form.Label>
                    <TextEditor
                        name="atAGlance"
                        value={course.atAGlance || ""}
                        handleInputs={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Course Fee paragraph</Form.Label>
                    <TextEditor
                        name="courseFeeLine"
                        value={course.courseFeeLine || ""}
                        handleInputs={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Course fee in PKR </Form.Label>
                    <Form.Control
                        name="courseFee"
                        type="number"
                        value={course.courseFee}
                        onChange={handleInputs}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Join Now Youtube Link </Form.Label>
                    <Form.Control
                        name="joinNowYoutubeLink"
                        type="url"
                        value={course.joinNowYoutubeLink}
                        onChange={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        name="isAdminssionOpen"
                        type="switch"
                        checked={course.isAdminssionOpen}
                        onChange={handleInputs}
                        label="Admission Open"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        name="isPaid"
                        type="switch"
                        checked={course.isPaid}
                        onChange={handleInputs}
                        label="Paid"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Instructor Name  </Form.Label>
                    <Form.Control
                        name="instructor"
                        type="text"
                        value={course.instructor}
                        onChange={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Instructor Image  </Form.Label>
                    <Form.Control
                        name="instructorImage"
                        type="text"
                        value={course.instructorImage}
                        onChange={handleInputs}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label> Instructor Description </Form.Label>
                    <TextEditor
                        name="instructorDisc"
                        value={course.instructorDisc || ""}
                        handleInputs={handleInputs}
                    />
                </Form.Group>
                <div className="pt-2">
                    <div className="text-end w-100">
                        <Button onClick={addFeature}>
                            <FaPlusCircle size={15} /> &nbsp; Add Youtube Links
                        </Button>
                    </div>
                </div>
                <div>
                    {course.youtubeLinks.map((f, index) => {
                        return (
                            <div key={index} className="feature-item my-2 p-1">
                                <div className="editAbleInputContainer">
                                    <input
                                        type="url"
                                        className="editAbleInput"
                                        onChange={(e) => handleFeatureInput(e, index)}
                                        onDoubleClick={(e) => (e.target.readOnly = false)}
                                        onBlurCapture={(e) => (e.target.readOnly = true)}
                                        value={f}
                                    />
                                </div>
                                <div className="ps-1 editDeleteBtnContainer">
                                    <FaTrashAlt
                                        className="icon"
                                        onClick={() => deleteFeature(index)}
                                    />
                                </div>
                                <div className="clear" />
                            </div>
                        );
                    })}
                </div>
                <br />
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="submit" style={{ width: "80%" }}>
                        {params?.id ? <>Update</> : <>Save</>}
                    </Button>
                </div>
            </Form>
        </Container>
    )
}

export default AddCourse
