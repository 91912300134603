import GetServerUrl from "./GetServerUrl";
import axios from "axios";

const post = async (data) => {
  return await axios.post(`${GetServerUrl()}/api/books`, data);
};

const get = async () => {
  return await axios.get(`${GetServerUrl()}/api/books`);
};

const getById = async (id) => {
  return await axios.get(`${GetServerUrl()}/api/books/${id}`);
};

const patch = async (id, data) => {
  return await axios.patch(`${GetServerUrl()}/api/books/${id}`, data);
};

const _delete = async (id) => {
  return await axios.delete(`${GetServerUrl()}/api/books/${id}`);
};

const Books = {
    post,
    get,
    getById,
    patch,
    _delete
}

export default Books;