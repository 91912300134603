import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import photo from "../../../assets/images/dummydesigner.png";
import {IoMdCheckmark} from "react-icons/io";
import { Button } from "react-bootstrap";

const style = {
  imgStyle: {
    width: "100%",
    // height: "100%",
    objectFit: "contain",
    objectPosition: "center"
  },
  fullWidth: {
    width: "100%"
  }
}

const SeerahDetail = () => {
  return (
    <>
      <br />
      <br />
      <Container className="p-5">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <Image src={photo} thumbnail />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <div className="p-5">
              <h1 className="text-primary">
                Linguistics of Surrah Fatiha
              </h1>
              <p className="text-primary">
                this is three years course in which we study Linguistics of surrah fatiha in detail.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {
            [
              ["this is three years course in which we study Linguistics of surrah fatiha in detail.",
              "this is three years course in which we study Linguistics of surrah fatiha in detail.",
              "this is three years course in which we study Linguistics of surrah fatiha in detail.",],
              ["this is three years course in which we study Linguistics of surrah fatiha in detail.",
              "this is three years course in which we study Linguistics of surrah fatiha in detail.",
              "this is three years course in which we study Linguistics of surrah fatiha in detail.",],
              ["this is three years course in which we study Linguistics of surrah fatiha in detail.",
              "this is three years course in which we study Linguistics of surrah fatiha in detail.",
              "this is three years course in which we study Linguistics of surrah fatiha in detail.",],
            ]
            .map((ele, index)=>{
              return(
                <Col key={index} className="p-5 box-shadow" xl={4} lg={4} md={6} sm={12} xs={12}>
                  {
                    ele.map((text, i)=>{
                      return(
                        <div key={i}>
                          <div className="d-flex">
                              <span className="text-primary"><IoMdCheckmark size="25px" /></span>
                              <p className="px-2 text-primary">
                                {text}
                              </p>
                          </div>
                        </div>
                      )
                    })
                  
                    
                  }
                  {
                    index === 2 &&
                    <Button style={style.fullWidth}> Join Now </Button>
                  }
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </>
  );
};

export default SeerahDetail;
