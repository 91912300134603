import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import ImagesAPI from '../../API/Images';
import CoursesCard from '../../Component/Card/CourseCard';
import { FaEdit, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import GetServerUrl from "../../API/GetServerUrl";
import { toast } from "react-toastify";


const HandleCourseImages = () => {
  const [allImages, setAllImages] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    getAllImages();
  }, []);

  const getAllImages = async () => {
    await ImagesAPI.get()
      .then(res => {
        if (res && res.data && Array.isArray(res.data)) {
          setAllImages(res.data);
        }
      })
  }

  const openConfirmModal = () => setConfirmModal(true);
  const closeConfirmModal = () => setConfirmModal(false);

  const toggleAddModal = (el) => {
    if (el) {
      setSelectedImage(el);
    } else {

      setImage(null);
      setImageUrl(null);
      setSelectedImage(null);
    }
    setAddModel(!addModel);
  };

  const confirmDeleteCourse = (course) => {
    setSelectedImage(course);
    openConfirmModal();
  };


  const handleDelete = () => {
    console.log(selectedImage);
  }
  const handleEdit = () => {
    document.getElementById("uploadCourseImage").click();
  }

  const handleImageUpload = (event) => {
    setImage(event.target.files[0]);
    getBase64(event.target.files[0]);
  }

  const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setImageUrl(reader.result);
    reader.onerror = (error) => console.log("Error while reading image");
  }

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("image", image);
    if(!image){
      toast.warn("Select an image");
      return;
    }
    if (selectedImage && selectedImage._id) {

      await ImagesAPI.patch(selectedImage._id, formData)
        .then(res => {
          if (res && res.data) {
            toast.dark(res.data?.msg || "Error occured");
          }
        })
        .catch(res => {
          toast.dark("Error occured");
        })
    } else {
      await ImagesAPI.post(formData)
        .then(res => {
          if (res && res.data) {
            toast.dark(res.data?.msg || "Error occured");
          }
        })
        .catch(res => {
          toast.dark("Error occured");
        })
    }
    getAllImages();
    toggleAddModal();
  }

  return (
    <section>
      <input type="file" id="uploadCourseImage" hidden onChange={handleImageUpload} />
      <Button className="btn btn-primary" onClick={() => toggleAddModal()}>
        <FaPlusCircle size={15} /> &nbsp; Add
      </Button>
      <Modal show={confirmModal} onHide={closeConfirmModal} centered>
        <Modal.Header closeButton>Are you sure to delete?</Modal.Header>
        <Modal.Body>
          <Button variant="danger" onClick={handleDelete}>
            Yes! delete
          </Button>
          &nbsp;
          <Button
            onClick={closeConfirmModal}
            style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
          >
            No
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={addModel} onHide={() => toggleAddModal()} centered>
        <Modal.Header closeButton>Upload Image</Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            <img src={imageUrl} alt="upload_image" height={200} width={200} style={{ borderRadius: "50%" }} />
            <br />
            <br />
            <Button onClick={handleEdit}>
              Change
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
          &nbsp;
          <Button
            onClick={() => toggleAddModal()}
            style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row className="my-5">
          {allImages.map((el) => {
            return (
              <Col key={el._id} xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="d-flex justify-content-end">
                  <button className="btn" onClick={() => toggleAddModal(el)}> <FaEdit /> </button>
                  <button className="btn" onClick={() => confirmDeleteCourse(el)}> <FaTrashAlt /> </button>
                </div>
                <CoursesCard
                  img={GetServerUrl() + "/images/" + el.name + "?" + new Date().toISOString()}
                  title={
                      <div className='text-start'>
                        <p> {el.name} </p>
                        <p> {GetServerUrl() + "/images/" + el.name} </p>
                      </div>
                  }
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  )
}

export default HandleCourseImages;
