import React, { PropTypes, useEffect, useState } from 'react'
import RichTextEditor from 'react-rte';

const TextEditor = ({ value = "", handleInputs, name = "editor" }) => {

  const [textValue, setTextValue] = useState(RichTextEditor.createValueFromString(value, "html"));

  useEffect(()=>{
    const richValue = RichTextEditor.createValueFromString(value, "html");
    if(value && value !== richValue){
      setTextValue(richValue);
    }
  }, [value]);

  const handleUpdatePropsValue = ()=>{
    handleInputs({target: {value: textValue.toString("html"), name, type: "editor"}});
  }

  return (
    <div>
      <div style={{ padding: "10px 0px", "paddingBottom": "50px" }}>
        <RichTextEditor
          value={textValue}
          onChange={(v)=>{
            setTextValue(v);
          }}
        />
        <button type='button' className='btn btn-primary w-100 mt-1' onClick={handleUpdatePropsValue}> Save </button>
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  )
}

export default TextEditor