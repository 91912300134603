import React from "react";
import "./CourseCard.css";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from "../../assets/images/bismillah.jpg";

const CoursesCard = (props) => {
  return (
    <>
      <Card className="my-2 py-3 px-4 box_shadow course-card">
        {
          props.route ?
          <Link to={props.route} className="text-center">
            <Card.Img
              src={props.img ? props.img : img}
              alt="directiontodivine card image"
              className="cardImg"
            />
            <Card.Body>
              <Card.Title className="text-center text-primary cardTitle">
                {props.title ? props.title : <>Card Title</>}
              </Card.Title>
            </Card.Body>
          </Link> :
          <div className="text-center">
            <Card.Img
              src={props.img ? props.img : img}
              alt="directiontodivine card image"
              className="cardImg"
            />
            <Card.Body>
              <Card.Title className="text-center text-primary cardTitle">
                {props.title ? props.title : <>Card Title</>}
              </Card.Title>
            </Card.Body>
          </div>
        }
      </Card>
    </>
  );
};

export default CoursesCard;
