import React, { useEffect, useState } from "react";
import "./Courses.css";
import { Col, Container, Row } from "react-bootstrap";
import CoursesCard from "../../Component/Card/CourseCard";
import { BsSearch } from "react-icons/bs";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import course_bg from "../../assets/images/course_page_laptop.JPG";
import CoursesAPI from "../../API/Courses";
import "./SearchFilter.css";
import GetServerUrl from "../../API/GetServerUrl";
const Courses = () => {

  const [allCourses, setAllCourses] = useState([]);

  useEffect(() => {
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    await CoursesAPI.get()
      .then(res => {
        if(res && res.data && Array.isArray(res.data)){
          setAllCourses(res.data);
        }
      }).catch(err => {
      })
  }

  const style = {
    searchBox: {
      width: "100%",
      border: "1px solid #aaa",
      display: "flex",
      padding: "5px 0px",
      borderRadius: "8px",
    },
    input: {
      width: "100%",
      height: "30px",
      border: "none",
      margin: "0px 10px",
    },
  };

  return (
    <>
      <div>
        <div className="courses_conainer">
          <img
            src={course_bg}
            className="w-100"
            alt="directiontodivine course bg"
          />
        </div>
        <Container fluid>
          <div className="pt-5">
            <h1 className="text-primary text-center">Search for Courses</h1>
            <div className="text-center">
              <Row className="m-3">
                <Col xl={8} lg={8} md={12} sm={12} xs={12} className="mx-auto">
                  <div style={style.searchBox} className="search-box">
                    <BsSearch size={25} className="icon"></BsSearch>
                    <input style={style.input} placeholder="Search..." />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Container>
            <Row className="categories-title">
              <h4 className="text-center">
                or<b> Browse</b> Categories
              </h4>
            </Row>
            <Row className="my-5">
              {allCourses.map((el) => {
                return (
                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                    <CoursesCard
                      route={`/CourseDetails/${el._id}`}
                      img={GetServerUrl() + "/images/" + el.courseImg}
                      title={el.name}
                      discription={el.discription}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Container>
      </div>
      <div className="AyatContainer">
        <Container>
          <div className="trapezoid">
            <div className="trapzoid_text p-3">
              <p className="Ayat pt-4 px-2 text-center">
                <span className="pe-3">
                  <ImQuotesLeft className="quotes" />
                </span>
                If anyone pursues a path in search of knowledge, Allah will then
                by make easy for him a path to paradise; and he who is made slow
                by his actions will not be speeded by his genealogy
                <span className="ps-3">
                  <ImQuotesRight className="quotes" />
                </span>
              </p>
              <p className="Ayat text-center">Sunan Abi Dawud 3643</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Courses;
