import GetServerUrl from "./GetServerUrl";
import axios from "axios";

const post = async (data) => {
  return await axios.post(`${GetServerUrl()}/api/admin`, data);
};

const login = async (data) => {
  return await axios.post(`${GetServerUrl()}/api/auth/login`, data);
};

const get = async () => {
  return await axios.get(`${GetServerUrl()}/api/admin`);
};

const getById = async (id) => {
  return await axios.get(`${GetServerUrl()}/api/admin/${id}`);
};

const patch = async (id, data) => {
  return await axios.patch(`${GetServerUrl()}/api/admin/${id}`, data);
};

const _delete = async (id) => {
  return await axios.delete(`${GetServerUrl()}/api/admin/${id}`);
};

const AdminAPI = {
    post,
    get,
    getById,
    patch,
    _delete,
    login,
}

export default AdminAPI;