import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Courses from "../../API/Books";
import GetServerUrl from "../../API/GetServerUrl";
import CoursesCard from "../../Component/Card/CourseCard";
import {toast} from "react-toastify"

const ManageCourses = () => {

  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [allCourses, setAllCourses] = useState([]);

  useEffect(() => {
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    await Courses.get()
      .then(res => {
        if (res && res.data && Array.isArray(res.data)) {
          setAllCourses(res.data);
        }
      }).catch(err => {
      })
  }

  const openConfirmModal = () => setConfirmModal(true);
  const closeConfirmModal = () => setConfirmModal(false);

  const confirmDeleteCourse = (course) => {
    setSelectedCourse(course);
    openConfirmModal();
  };


  const handleDelete = async ()=>{
    if(selectedCourse && selectedCourse._id){
      await Courses._delete(selectedCourse._id)
      .then(res=>{
          toast.dark(res?.data?.msg || "Error occured")
        })
        .catch(err=>{
          toast.dark("Error occured")
      })
      getAllCourses();
      closeConfirmModal();
    }
  }

  return (
    <Container>
      <Modal show={confirmModal} onHide={closeConfirmModal} centered>
        <Modal.Header closeButton>Are you sure to delete?</Modal.Header>
        <Modal.Body>
          <Button variant="danger" onClick={handleDelete}>
            Yes! delete
          </Button>
          &nbsp;
          <Button
            onClick={closeConfirmModal}
            style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
          >
            No
          </Button>
        </Modal.Body>
      </Modal>
      <Link to="/Admin/AddBook" className="btn btn-primary">
        <FaPlusCircle size={15} /> &nbsp; Add
      </Link>
      <Row className="my-5">
        {allCourses.map((el) => {
          return (
            <Col key={el._id} xl={4} lg={4} md={6} sm={6} xs={12}>
              <div className="d-flex justify-content-end">
                <button className="btn" onClick={() => navigate(`/Admin/EditBook/${el._id}`)}> <FaEdit /> </button>
                <button className="btn" onClick={()=>confirmDeleteCourse(el)}> <FaTrashAlt /> </button>
              </div>
              <CoursesCard
                route={`/Admin/CourseDetails/${el._id}`}
                img={GetServerUrl() + "/images/" + el.img}
                title={el.name}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ManageCourses;
