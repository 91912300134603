import React, { useState } from "react";
import AdmissionContainer from "../../../Component/Admission/Admission";
import { Col, Container, Row, Image, Modal, Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { withRouter } from "../../../Utils/withRouter";
import course2_img from "../../../assets/images/course2.jpeg";
import course1_tutor from "../../../assets/images/course1_tutor.jpeg";
import course_2_img from "../../../assets/images/course_2_img.jpeg";
import "../../../Component/TeamSlider/TeamSlider.css";
import "../Courses.css";

const style = {
  imgStyle: {
    width: "100%",
    // height: "100%",
    objectFit: "contain",
    objectPosition: "center",
  },

  joinBtnWidth: {
    display: "block",
    backgroundColor: "var(--bs-primary)",
    color: "white",
    textDecoration: "none",
    width: "60%",
    padding: "10px",
    textAlign: "center",
  },
};

const CourseDetails = ({ navigate, ...props }) => {
  const [proceedModal, setProcessModal] = useState(false);
  const course = {
    name: "Quran's Language Program",
    discription: `
          Learn the foundational concepts pertaining to Quranic Arabic and gain a 
          deeper appreciation of the Book that Allah SWT sent down as mercy and 
          guide to all of humanity. <br />
          Language of Quran is a unique program designed to meet the interest of Muslims in learning Arabic 
          in order to develop sufficient language competence to acquire Islamic knowledge from original 
          Arabic primary texts. understand that materializing this course is a step by step process and we hope 
          to share with you how we can together be part of making this dream a reality.
          <br />
          Students begin their journey with the study of foundational Arabic grammar and morphology. 
          Throughout the course, they build the skills necessary to read and understand the Qur’an as well as 
          classical Islamic texts in Arabic. It is our goal to connect students to the Quran, sacred knowledge, 
          and the Islamic tradition through the study of the Arabic language while remaining focused on two 
          main proficiencies: <br/ >
          1: To understand the Quran without a translation. <br />
          2: To read and translate classical Arabic text <br />`,
    course_tagline: "Start Your Journey Through The Quran’s Language",
    course_img: course2_img,
    tutor: "Ma'am Zill e Huma",
    tutor_dics: `Zillehuma Founder and Director of Direction to Divine studied shariyah sciences and student of "Alimiyyah"`,
    tutor_img: course1_tutor,
    what_you_will_learn: `Students will get deep applied knowledge of Arabic learning. They will be able to understand the
          original Arabic language of the Quran.`,
    course_structure: [
      {
        heading: "LEVEL ONE NAHW: ",
        disc: `The science of Arabic grammar (nahw) is the oldest and one of the most important of all 
                  the Islamic sciences. It began in the time of the Companions, when the mistakes of nonArabian converts to Islam began adulterating the linguistic purity of the ancient Arabic 
                  language. The early grammarians preserved this ancient language through formal rules, 
                  thereby preserving our ability to understand the Qur’an and Sunna. In this level we will 
                  apply the most foundational knowledge of Arabic grammar. You will master the 
                  grammatical characteristics of nouns, verbs, prepositions, fragmented phrases and basic 
                  complete sentences directly in the Quran.`,
      },
      {
        heading: "LEVEL TWO SARF: ",
        disc: `Sarf is the study of morphology. That’s just a fancy way of saying word structure, word 
                  patterns, and how words are formed!. That means it is the study of how the spellings of 
                  words impact what they mean in Arabic. In this unit students gain foundational knowledge 
                  of the system of Arabic morphology as it is broken into verb patterns and families. This 
                  knowledge will then help students acquire vocabulary at an exponentially faster rate and 
                  also sharpen their ability to decipher meanings of individual words based on their spelling 
                  patterns`,
      },
      {
        heading: "LEVEL THREE ADVANCED SARF:",
        disc: `This unit consists of the study of irregular verb and noun conjugations, the implications of 
                  complex conjugations and the meanings behind the patterns of Arabic spellings`,
      },
      {
        heading: "LEVEL FOUR ADVANCED NAHW:",
        disc: `This unit includes the study of advanced sentence structure, complex fragments, issues in 
                  translation, multiple grammatical ways of looking at the same ayaat and the impact of each 
                  on translation and the science of I’rab (grammatical analysis).`,
      },
      {
        heading: "LEVEL FIVE RHETORIC (BALAGHAH) :",
        disc: `Rhetoric means balaghah. It is the study of the powerful tools in the language that a speaker 
                  uses to have deep impact on his or her listeners. This unit utilizes unique method of teaching 
                  the classical science of Arabic rhetoric in an applied fashion. Examples used are either every 
                  day speech or Qur’an. Students gain a literary and linguistic appreciation of the Qur’an’s 
                  artistic style`,
      },
    ],
    middle_img: course_2_img,
    unlock_quran_essentials: {
      name: "UNLOCK QURAN ESENTIALS",
      disc: `UnlockQuran essentials is a course specifically for students that have learned Arabic 
              Grammar in this course and would like to master their knowledge.This mastery is gained by 
              direct application in the Quran. This Level is the fruit of all previous labor. It is a walkthrough of 
              surat AL-BAQARAH analyzing all of the nahw, sarf and balaghah previously studied so students have 
              deep applied knowledge of their Arabic learning through the largest surah of the Qur’an. Explore this 
              surah in a way you’ve never thought possible.Discover numerous gems as you perform an 
              in-depth grammatical analysis of the verses of this surah, all the while applying all the 
              grammar that we’ve covered in UnlockQuran Essentials. ( It’s a separate course ).
              `,
    },
    at_a_glance: [
      "Completely Online",
      "Flexible",
      "Classes ( Monday, Wednesday, Friday)",
      "Q/A Session ( On weekends )",
      "Lecture Format ( PowerPoint Presentations ) ",
      "Vocabulary Learning Through Animated Videos",
      "Quiz Games And Weekly Assessments",
      "Illustrated Posters Of Deep Concepts",
      "Zoom Recorded Sessions On YouTube",
      "WhatsApp Student Support groups",
      "Charges Apply Monthly",
      "QURAN’S LANGUAGE PROGRAME Duration ( 3 years ) ",
      "UNLOCK QURAN ESSENTIALS Duration ( 1.5 year)",
      "Certified Course",
    ],
    youtube_links: ["https://youtube.com/embed/Crywxjp6ZpI"],
    is_paid: true,
    course_fee: "1500 PKR",
    is_admission_open: true,
  };

  const toggleProceedModal = () => setProcessModal(!proceedModal);

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <Modal show={proceedModal} onHide={toggleProceedModal} centered>
        <Modal.Header>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you are pakistan national then your fee for Quran's Language
          Program is 1500 PKR per month. For international students it's 4000
          PKR per month.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={toggleProceedModal}>
            Cancel
          </Button>
          &nbsp;
          <Button variant="primary" onClick={() => navigate("/Registration")}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <AdmissionContainer /> */}
      <Container className="p-5 course_detail_container">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <Image src={course.course_img} loading="lazy" thumbnail />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <div className="course_detail">
              <h1 className="text-primary">{course.name}</h1>
              <p
                className="w-100 detail_text"
                dangerouslySetInnerHTML={{ __html: course.discription }}
              ></p>
            </div>
          </Col>
        </Row>

        <div className="container">
          <h1 className="text-primary">Course Structure</h1>
          <br />
          {course.course_structure.map((el, index) => {
            return (
              <div key={index}>
                <p className="text-primary course_structure_heading">
                  {index + 1}. {el.heading}
                </p>
                <p className="w-100">{el.disc}</p>
              </div>
            );
          })}
        </div>

        <div className="text-center my-5">
          <img
            src={course.middle_img}
            className="w-100"
            alt="directiontodivine course 2"
          />
        </div>

        <div className="my-5">
          <h1 className="text-primary">
            {course.unlock_quran_essentials.name}
          </h1>
          <p>{course.unlock_quran_essentials.disc}</p>
        </div>
        {course.at_a_glance && (
          <>
            <div className="my-5">
              <h1 className="text-primary">At a glance</h1>
              <p>
                {course.at_a_glance.map((el, i) => (
                  <p key={i}>
                    <FaCheck size={15} /> &nbsp; {el}
                  </p>
                ))}
              </p>
            </div>
          </>
        )}
        <div className="py-5">
          <h1 className="text-primary"> Course Fee: </h1>
          <p className="px-3">
            If you are pakistan national then your fee for Quran's Language
            Program is 1500 pkr per month. For international students it's 4000
            PKR per month.
          </p>
        </div>
        <div className="py-5">
          {course.youtube_links.map((el) => (
            <iframe
              src={el}
              className="w-100"
              title="Youtube Video"
              style={{ minHeight: "400px" }}
            ></iframe>
          ))}
        </div>
        <div className="py-5">
          <h1 className="text-center text-primary py-3">Instructor</h1>
          <div className="teamCard my-3 box_shadow mx-auto">
            <div className="bgLightArea"></div>
            <div className="bgGreyArea"></div>
            <div className="teamBio">
              <div className="teamImgBorder text-center">
                <img
                  src={course.tutor_img}
                  style={{ objectPosition: "top" }}
                  height="70px"
                  width="70px"
                  alt="team member"
                />
              </div>
              <div className="pt-1 teamInfo">
                <p className="TeamName text-center"> {course.tutor} </p>
              </div>
              <div className="TeamDiscription mt-3">
                <p>{course.tutor_dics}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center my-3">
          <Button
            style={style.joinBtnWidth}
            onClick={() => toggleProceedModal()}
          >
            Join Now
          </Button>
        </div>
      </Container>
      <div className="AyatContainer">
        <Container>
          <div className="trapezoid">
            <div className="trapzoid_text p-3">
              <p className="Ayat pt-4 px-2 text-center">
                <span className="pe-3">
                  <ImQuotesLeft className="quotes" />
                </span>
                Abu Hurairah reported the Prophet (ﷺ) as saying: <br />
                If anyone pursues a path in search of knowledge, Allah will then
                by make easy for him a path to paradise; and he who is made slow
                by his actions will not be speeded by his genealogy.
                <span className="ps-3">
                  <ImQuotesRight className="quotes" />
                </span>
              </p>
              <p className="Ayat text-center">Sunan Abi Dawud 3643</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(CourseDetails);
