import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { Routes, Route } from "react-router";
import logo from "../../assets/images/Logo-Divine-Gold (1).png";
import Home from "./Home";
import Sidebar from "./Sidebar";
import { withRouter } from "../../Utils/withRouter";
import ManageCourses from "./ManageCourses";
import ManageBooks from "./ManageBooks";
import ManageDonation from "./ManageDonation";
import ManageRegistration from "./ManageRegistration";
import "./admin.css";
import { Link } from "react-router-dom";
import CourseDetails from "../Courses/CourseDetails/CourseDetails";
import AddCourse from "./AddCourse";
import HandleCourseImages from "./HandleCourseImages";
import AddBook from "./AddBook";
import ManageAccounts from "./ManageAccounts";

const Admin = ({ navigate, ...props }) => {
  const [frame, setFrame] = useState(false);
  const [show_side_bar, setShowSideBar] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("dtd:user");
    if (data) {
      setFrame(true);
    } else {
      navigate("/admin/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const toggleSideBar = () => setShowSideBar(!show_side_bar);
  
  useEffect(() => {
    show_side_bar && toggleSideBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);


  return (
    <>
      {frame && (
        <div className="admin_panel">
          {show_side_bar && (
            <div className="admin_side_bar_float">
              <Sidebar toggleSideBar={toggleSideBar} />
            </div>
          )}
          <div className="admin_header d-flex justify-content-between py-2 px-3 shadow">
            <div className="side_bar_toggler pt-4">
              <FaBars size={35} onClick={toggleSideBar} />
            </div>
            <div>
              <Link to="/Admin/Home">
                <Image src={logo} height="80px" />
              </Link>
            </div>
          </div>
          <div className="d-flex">
            <div className="admin_side_bar_container">
              <Sidebar toggleSideBar={toggleSideBar} />
            </div>
            <div className="admin_action_container ps-3 pt-3" style={{minHeight: "100vh"}}>
              <Routes>
                <Route path="/Home" element={<Home />} />
                <Route path="/CourseDetails/:id" element={<CourseDetails />} />
                <Route path="/CoursesImages" element={<HandleCourseImages />} />
                <Route path="/AddCourse" element={<AddCourse />} />
                <Route path="/AddBook" element={<AddBook />} />
                <Route path="/EditCourse/:id" element={<AddCourse />} />
                <Route path="/EditBook/:id" element={<AddBook />} />
                <Route path="/ManageCourses" element={<ManageCourses />} />
                <Route path="/ManageBooks" element={<ManageBooks />} />
                <Route path="/ManageDonations" element={<ManageDonation />} />
                <Route path="/ManageAccounts" element={<ManageAccounts />} />
                <Route
                  path="/ManageRegisterations"
                  element={<ManageRegistration />}
                />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(Admin);
