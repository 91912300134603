import GetServerUrl from "./GetServerUrl";
import axios from "axios";

const post_register = async (data) => {
  return await axios.post(`${GetServerUrl()}/api/register`, data);
};

const post_donation = async (data) => {
  return await axios.post(`${GetServerUrl()}/api/donation`, data);
};

const get_register_by_email_or_phone = async ({ email, phone }) => {
  return await axios.get(
    `${GetServerUrl()}/api/register/find?email=${email}&phone=${phone}`
  );
};

const get_register = async () => {
  return await axios.get(`${GetServerUrl()}/api/register`);
};

const delete_registeration_by_id = async (_id) => {
  return await axios.delete(`${GetServerUrl()}/api/register/${_id}`);
};

const update_register_by_id = async (_id, data) => {
  return await axios.put(`${GetServerUrl()}/api/register/${_id}`, data);
};

const get_donations = async () => {
  return await axios.get(`${GetServerUrl()}/api/donation`);
};

const delete_donation_by_id = async (id) => {
  return await axios.delete(`${GetServerUrl()}/api/donation/${id}`);
};

const update_donation_by_id = async (id, data) => {
  return await axios.put(`${GetServerUrl()}/api/donation/${id}`, data);
};

const get_register_by_id = async (id) => {
  return await axios.get(`${GetServerUrl()}/api/register/${id}`);
};

const ServerActions = {
  post_register,
  post_donation,
  get_register,
  delete_registeration_by_id,
  update_register_by_id,
  get_register_by_email_or_phone,
  get_donations,
  delete_donation_by_id,
  update_donation_by_id,
  get_register_by_id,
};

export default ServerActions;
