import Admin from "./Admin";
import Home from "./Home";
import Login from "./Login";
import ManageCourses from './ManageCourses';
import ManageBooks from './ManageBooks';
import ManageDonation from './ManageDonation';
import ManageRegistration from './ManageRegistration';
import Logout from "./Logout";

const AdminRoutes = {
    Home,
    Login,
    Admin,
    ManageBooks,
    ManageRegistration,
    ManageDonation,
    ManageCourses,
    Logout,
}

export default AdminRoutes;