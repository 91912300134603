import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { withRouter } from "../../../Utils/withRouter";
import course1_img from "../../../assets/images/course1.jpeg";
import course1_tutor from "../../../assets/images/course1_tutor.jpeg";
import orientation_img from "../../../assets/images/orientation.jpeg";
import "../../../Component/TeamSlider/TeamSlider.css";
import "../Courses.css";
const style = {
  imgStyle: {
    width: "100%",
    // height: "100%",
    objectFit: "contain",
    objectPosition: "center",
  },

  joinBtnWidth: {
    display: "block",
    backgroundColor: "var(--bs-primary)",
    color: "white",
    textDecoration: "none",
    width: "60%",
    padding: "10px",
    textAlign: "center",
  },
};

const CourseDetails = () => {
  const course = {
    name: "Dream Arabic Intensive",
    discription: `
                  Dream Intensive is a Quranic Applicative Arabic course. <br />
                  This course is basically for those who are new to Arabic and want to learn the basics 
                  <br />
                  (A solid foundation to learn Quranic Arabic language). 
                  <br /> 
                  In this course, we dive deep into the grammar and linguistics of Quranic Arabic so that we can have a better 
                  approach to the versatility of the Quran.`,
    course_tagline: "Start Your Journey Through The Quran’s Language",
    course_img: course1_img,
    tutor: "Ma'am Zill e Huma",
    tutor_dics: `Zillehuma Founder and Director of Direction to Divine studied shariyah sciences and student of "Alimiyyah"`,
    tutor_img: course1_tutor,
    features: [
      "This course is divided into four intensives",
      "First and second intensives are on NAHW study",
      "Third and fourth intensives are on SARF study",
      "80 days course",
      "Language: English and Urdu",
      "Mode: Video recorded lectures on YouTube",
    ],
    what_you_will_learn: `Students will get deep applied knowledge of Arabic learning. They will be able to understand the
                  original Arabic language of the Quran.`,
    youtube_link: "https://youtu.be/_umr007a5BQ",
    is_paid: false,
    orientation_img,
  };

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <Container className="p-5 course_detail_container">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <Image src={course.course_img} loading="lazy" thumbnail />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <div className="course_detail">
              <h1 className="text-primary">{course.name}</h1>
              <p
                className="w-100 detail_text"
                dangerouslySetInnerHTML={{ __html: course.discription }}
              ></p>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="mb-2">
            <h1 className="text-primary">Features</h1>
          </div>
          {course.features.map((ele, index) => {
            return (
              <Col
                key={index}
                className="p-2 box-shadow"
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
              >
                {
                  <div key={index}>
                    <div className="d-flex">
                      <span className="text-primary">
                        <IoMdCheckmark size="25px" />
                      </span>
                      <p className="px-2 text-primary">{ele}</p>
                    </div>
                  </div>
                }
              </Col>
            );
          })}
        </Row>
        <div>
          <img
            src={course.orientation_img}
            className="w-100"
            alt="orientation"
          />
          <Button
            className="w-100 mt-3"
            onClick={() => window.open(course.youtube_link)}
          >
            Watch Orientation
          </Button>
        </div>

        <div className="py-5">
          <h1 className="text-center text-primary py-3">Instructor</h1>
          <div className="teamCard my-3 box_shadow mx-auto">
            <div className="bgLightArea"></div>
            <div className="bgGreyArea"></div>
            <div className="teamBio">
              <div className="teamImgBorder text-center">
                <img
                  src={course.tutor_img}
                  style={{ objectPosition: "top" }}
                  height="70px"
                  width="70px"
                  alt="team member"
                />
              </div>
              <div className="pt-1 teamInfo">
                <p className="TeamName text-center"> {course.tutor} </p>
              </div>
              <div className="TeamDiscription mt-3">
                <p>{course.tutor_dics}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center my-3">
          <Link
            style={style.joinBtnWidth}
            to="#"
            onClick={() => window.open(course.youtube_link)}
          >
            Join Now
          </Link>
        </div>
      </Container>
      <div className="AyatContainer">
        <Container>
          <div className="trapezoid">
            <div className="trapzoid_text p-3">
              <p className="Ayat pt-4 px-2 text-center">
                <span className="pe-3">
                  <ImQuotesLeft className="quotes" />
                </span>
                Abu Hurairah reported the Prophet (ﷺ) as saying: <br />
                If anyone pursues a path in search of knowledge, Allah will then
                by make easy for him a path to paradise; and he who is made slow
                by his actions will not be speeded by his genealogy.
                <span className="ps-3">
                  <ImQuotesRight className="quotes" />
                </span>
              </p>
              <p className="Ayat text-center">Sunan Abi Dawud 3643</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(CourseDetails);
