import React from "react";
import "./Seerah.css";
import {Col, Container, Row} from "react-bootstrap";
import CoursesCard from "../../Component/Card/CourseCard";
import { BsSearch } from "react-icons/bs";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

import "./SearchFilter.css";
const Seerah = () => {

  
  const style = {
    searchBox: {
      width: "100%",
      border: "1px solid #aaa",
      display: "flex",
      padding: "5px 0px",
      borderRadius: "8px",
    },
    input:{
      width: "100%",
      height: "30px",
      border: "none",
      margin: "0px 10px",

    }
  }

  return (
    <>
      <div className="seerah_background_image" />
        <Container fluid>
          <div className="pt-5">
            <h1 className="text-primary text-center">Search for Seerah Series</h1>
            <div className="text-center">
            <Row className="m-3">
              <Col xl={8} lg={8} md={12} sm={12} xs={12} className="mx-auto">
                <div style={style.searchBox} className="search-box">
                  <BsSearch size={25} className="icon"></BsSearch>
                  <input 
                    style={style.input}
                    placeholder="Search..."
                    />                  
                </div>
              </Col>
            </Row>
            </div>
          </div>
          <Container>
            <Row className="categories-title">
              <h4 className="text-center">
                or<b> Browse</b> Categories
              </h4>
            </Row>
            <Row className="my-5">
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <CoursesCard route="/SeerahDetails"/>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <CoursesCard route="/SeerahDetails"/>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <CoursesCard route="/SeerahDetails"/>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <CoursesCard route="/SeerahDetails"/>
              </Col>
            </Row>
          </Container>
        </Container>
        <div className="AyatContainer">
          <Container>
              <div className="trapezoid">
                  <div className="trapzoid_text p-3">
                      <p className="Ayat pt-4 px-2 text-center">
                          <span className="pe-3">
                              <ImQuotesLeft className="quotes" />
                          </span>
                          Indeed, in the messenger of Allah a ‘good example (uswatun hasana)  has been set for
                          the one who seeks Allah and the Last Day and thinks constantly about Allah.
                          <span className="ps-3">
                              <ImQuotesRight className="quotes"/>
                          </span>
                      </p>
                      <p className="Ayat text-center">
                      (Qur’an 33:21)
                      </p>
                  </div>
              </div>
          </Container>
      </div>
    </>
  );
};

export default Seerah;
