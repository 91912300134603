import React, { useState, useRef } from "react";
import { Image, Overlay, Popover } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import whatsapp_img from "../../assets/images/whatsapp.png";
import logo from "../../assets/images/LOGO_green.png";

import "./whatsapp.css";
const WhatsAppButton = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
  };

  return (
    <>
      <div className="whatsappBtn" style={{ cursor: "pointer" }}>
        <Image
          onClick={handleClick}
          src={whatsapp_img}
          height="70px"
          alt="wa__btn"
          ref={target}
        />
        <Overlay show={show} target={target.current} placement="left">
          <Popover
            id="popover-positioned-right"
            className="zindex-popover wa__popover"
          >
            <Popover.Header className="wa__popover__header__content">
              <div className="d-flex justify-content-between py-3">
                <div className="text-light px-2">
                  <FaWhatsapp size={35} />
                </div>
                <div className="wa__popover_header px-2">
                  <h5>Start a Conversation</h5>
                  <p>
                    Hi! Click one of our member below to chat on{" "}
                    <strong> Whats App </strong>
                  </p>
                </div>
              </div>
            </Popover.Header>

            <Popover.Body>
              <p className="text-muted">
                The team typically replies in a few minutes.
              </p>
              <br />
              <div className="d-flex justify-content-between wa__team__contact py-3 px-2">
                <Image src={logo} width="70px" alt="dtd__logo" />
                <div className="wa__popup_txt px-3">
                  <div className="wa__member_name">DTD Helpline</div>
                  <div className="wa__member_duty">DTD Helpline for all</div>
                </div>
                <div style={{ color: "#2db742" }}>
                  <a
                    rel="noreferrer"
                    href="https://api.whatsapp.com/send?phone=923364414208&&text=*Salam, I have some queries against DTD online program's*"
                    target="_blank"
                  >
                    <FaWhatsapp size={25} />
                  </a>
                </div>
              </div>
              {/* <div className="d-flex justify-content-between wa__team__contact py-3 px-2">
                <Image src={logo} width="70px" alt="dtd__logo" />
                <div className="wa__popup_txt px-3">
                  <div className="wa__member_name">DTD Helpline</div>
                  <div className="wa__member_duty">
                    Only for enrolled students
                  </div>
                </div>
                <div style={{ color: "#2db742" }}>
                  <a
                    rel="noreferrer"
                    href="https://api.whatsapp.com/send?phone=923205486567&&text=*Salam, I am an DTD student and I need some help. DTD online Program*"
                    target="_blank"
                  >
                    <FaWhatsapp size={25} />
                  </a>
                </div>
              </div> */}
              {/* <br />
              <div className="d-flex justify-content-between wa__team__contact py-3 px-2">
                <Image src={logo} width="70px" alt="dtd__logo" />
                <div className="wa__popup_txt px-3">
                  <div className="wa__member_name">DTD Helpline</div>
                  <div className="wa__member_duty">
                    Enrollment related quries
                  </div>
                </div>
                <div style={{ color: "#2db742" }}>
                  <a
                    rel="noreferrer"
                    href="https://api.whatsapp.com/send?phone=923438078501&&text=*Salam, I have a query against DTD online programs*"
                    target="_blank"
                  >
                    <FaWhatsapp size={25} />
                  </a>
                </div>
              </div> */}
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </>
  );
};

export default WhatsAppButton;
