import React, { useEffect, useState } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ServerActions from "../../API/API";

const ManageDonation = () => {
  const [allDonations, setAllDonations] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [selectedDonation, setSelectedDonation] = useState({});

  const openConfirmModal = () => setConfirmModal(true);
  const closeConfirmModal = () => setConfirmModal(false);

  const fetch_donations = async () => {
    await ServerActions.get_donations()
      .then((res) => {
        if (res && res.data.length) {
          setAllDonations(res.data);
        } else {
          setAllDonations([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetch_donations();
  }, []);

  const confirmDeleteDonation = (index) => {
    setSelectedDonation(allDonations[index]);
    openConfirmModal();
  };

  const handleDelete = async () => {
    await ServerActions.delete_donation_by_id(selectedDonation._id)
      .then((res) => {
        console.log(res);
        closeConfirmModal();
        if (res?.data?.deletedCount === 1) {
          toast.success("Deleted");
        } else {
          toast.error("unable to delete");
        }

        fetch_donations();
      })
      .catch((err) => {});
  };

  const openVerificationModal = (index) => {
    setSelectedDonation(allDonations[index]);
    setVerificationStatus(true);
  };

  const handleVerificationStatus = async (state) => {
    await ServerActions.update_donation_by_id(selectedDonation._id, {
      approved: state,
    })
      .then((res) => {
        console.log(res);
        fetch_donations();
        setVerificationStatus(false);
        if (res?.data?.modifiedCount === 1) {
          toast.success("Update successfully");
        } else {
          toast.error("unable to update");
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="table-responsive-lg px-2 pt-3">
      <Modal show={confirmModal} onHide={closeConfirmModal}>
        <Modal.Header closeButton>Are you sure to delete?</Modal.Header>
        <Modal.Body>
          <Button variant="danger" onClick={handleDelete}>
            {" "}
            Yes! delete{" "}
          </Button>
          &nbsp;
          <Button
            onClick={closeConfirmModal}
            style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
          >
            {" "}
            No{" "}
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={verificationStatus}
        onHide={() => setVerificationStatus(false)}
      >
        <Modal.Header closeButton>Change Verification status to:</Modal.Header>
        <Modal.Body>
          <Button
            variant="success"
            onClick={() => handleVerificationStatus(true)}
          >
            {" "}
            Verified{" "}
          </Button>
          &nbsp;
          <Button
            onClick={() => handleVerificationStatus(false)}
            variant="danger"
          >
            Un Verified
          </Button>
        </Modal.Body>
      </Modal>
      <Table striped hover className="text-center align-middle">
        <thead>
          <tr>
            {[
              "#",
              "Name",
              "Email",
              "Phone",
              "Amount",
              "Company",
              "Verified",
              "Actions",
            ].map((el, index) => (
              <th className="text-center px-3" key={index}>
                {el}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allDonations.map((el, index) => {
            return (
              <tr key={index} style={{ fontSize: "14px" }}>
                <td>{index + 1}</td>
                <td>{el.name}</td>
                <td>{el.email}</td>
                <td>{el.phone}</td>
                <td>{el.amount}</td>
                <td>{el.company}</td>
                <td style={{ color: `${el.approved ? "green" : "red"}` }}>
                  {`${el.approved}`}
                </td>
                <td>
                  <Link
                    to="#"
                    className="m-1"
                    onClick={() => openVerificationModal(index)}
                  >
                    <FaEdit size={15} />
                  </Link>
                  &nbsp;
                  <Link
                    to="#"
                    className="m-1 text-danger"
                    onClick={() => confirmDeleteDonation(index)}
                  >
                    <FaTrash size={15} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageDonation;
