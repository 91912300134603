import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./TeamSlider.css";
import female_icon from "../../assets/images/female.jpg";
import male_icon from "../../assets/images/male.jpg";
import useWindowDimensions from "../../GetWidth";
import { Container } from "react-bootstrap";

// Import Swiper styles
const TeamSlider = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <Container fluid className="px-3 py-5">
        <div className="pt-4 meetTheTeam">
          <h1 className="text-center text-secondary"> Meet The Team </h1>
        </div>
        <div className="mb-3 meetTheTeam">
          <p className="w-75 text-center m-auto text-secondary">
            Direction to Divine consists of devoted people who are giving their
            energies and doing their work with full devotion in this enlightened
            path.
          </p>
        </div>
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={width > 1150 ? 3 : width < 750 ? 1 : 2}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          className="py-5"
        >
          {[
            {
              name: "Zille Huma",
              img: "",
              gender: "female",
              subTitle: "Founder & Director",
              disc: `Zillehuma Founder and Director of Direction to Divine studied shariyah sciences and student of "Alimiyyah"`,
            },
            {
              name: "Anam Arshid",
              gender: "female",
              img: "",
              subTitle: "Accounts Manager",
              disc: `Coordinator and Accounts Manager at Direction to Divine, a teacher by profession and studied Shariah sciences(As-Safwa)`,
            },
            {
              name: "Muhammad Aleem",
              gender: "male",
              img: "",
              subTitle: "Graphic Designer",
              disc: `Animation and Graphic Designer at Direction to Divine, an Electrical Engineer by profession and student of Shariah Sciences.`,
            },
            {
              name: "Azeem Iqbal",
              img: "",
              gender: "male",
              subTitle: "Website manager",
              disc: `Website manager at Direction to Divine, Lecturer at UET Lahore Pakistan.`,
            },
            {
              name: "Farzana Saleem",
              img: "",
              gender: "female",
              subTitle: "Editor at DTD",
              disc: `Editor at Direction to Divine, a teacher by profession and a student of the linguistic study of the Quran.`,
            },
            {
              name: "Haleema Zafar",
              img: "",
              subTitle: "Sketch Designer",
              gender: "female",
              disc: `Sketch Designer at Direction to Divine, a lecturer by profession and studied Shariah sciences(As-Safwa)`,
            },
            {
              name: "Sadia Kanwal",
              gender: "female",
              img: "",
              subTitle: "Presentation Designer",
              disc: `Presentation Designer at Direction to Divine and a student of BS English and pursuing a diploma in Quran studies from Minhaj-ul-Quran.`,
            },
            {
              name: "Sidra Mubeen",
              gender: "female",
              img: "",
              subTitle: "Social media manager",
              disc: `Social media manager at Direction to Divine, a teacher by profession, Hafizah and studied Shariah sciences(As-Safwa)`,
            },
            {
              name: "Rabia Khan",
              gender: "female",
              img: "",
              subTitle: "Typist at DTD",
              disc: `A typist at Direction to Divine and a student of Darasat-e-Dinia`,
            },
            {
              name: "Bushra Hussain",
              gender: "female",
              img: "",
              subTitle: "Typist at DTD",
              disc: `A typist at Direction to Divine, a mathematician by profession and currently doing a Taleem-ul-Quran course from Al-Huda International`,
            },
            {
              name: "Iqra Zaitoon",
              gender: "female",
              img: "",
              subTitle: "Typist at DTD",
              disc: `A typist at Direction to Divine, a student of MSc Psychology and linguistic study of Quran`,
            },
            {
              name: "Iqra Nasir",
              gender: "female",
              img: "",
              subTitle: "Typist at DTD",
              disc: `A typist at Direction to Divine, student of BS Physics and linguistic study of Quran.`,
            },
            {
              name: "Usman Hafeez",
              gender: "male",
              img: "",
              subTitle: "Solution provider",
              disc: `Solution provider at Direction to Divine, studied O LEVELS / A LEVELS ACCA Fundamental, Businessman`,
            },
          ].map((el, i) => {
            return (
              <SwiperSlide key={i + "TeamSlider"} className="swiperSlider">
                <div className="teamCard my-3 box_shadow">
                  <div className="bgLightArea"></div>
                  <div className="bgGreyArea"></div>
                  <div className="teamBio">
                    <div className="teamImgBorder text-center">
                      <img
                        src={el.gender === "male" ? male_icon : female_icon}
                        height="70px"
                        width="70px"
                        alt="directiontodivine team member"
                      />
                    </div>
                    <div className="pt-1 teamInfo">
                      <p className="TeamName text-center"> {el.name} </p>
                    </div>
                    <div className="TeamDiscription mt-3">
                      <p>{el.disc}</p>
                    </div>
                  </div>
                  <div className="bgGradient tagLine py-1">
                    <p className="text-center w-85 m-auto teamSbtitle">
                      {el.subTitle}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </>
  );
};

export default TeamSlider;
