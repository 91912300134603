import React from "react";
import "./AboutInitiative.css";
import { Container, Col, Row } from "react-bootstrap";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

const AboutInitiative = () => {
  return (
    <>
      <Container fluid className="bg-secondary">
        <Row className="about-main-row">
          <Col lg={3} md={12} sm={12} xs={12} className="col-1">
            <p className="col-1-text-1">About the</p>
            <p className="col-1-text-2">initiative.</p>
            <br />
            <div className="mobileViewAbout">
              <p className="col-2-text">
                Direction to Divine is the reality of the dream seen by Ma’m
                Zille Huma in 2017. Ma'm Zille Huma has done Master’s in English
                Literature from the University of Punjab, Pakistan and studied
                linguistics as a subject in it. Her pursuit of Islamic knowledge
                started when she began to attend the Arabic linguistic lecture
                series by Ustadh Nouman Ali khan in 2014 and various other
                scholars.
              </p>
              <p className="col-2-text">
                She is doing an Arabic language programme by Bayyinah Institute
                and further studying Lughat-ul-Arabia. She has done Higher
                Academic Studies in Shariah Sciences, in which she studied
                multiple courses that include Aqeedah, Usool-e-Fiqh,
                Usool-e-Tafseer, Usool-e-Hadith, Amsaal-ul-Quraan, Arabic
                grammar etc. She began to do linguistic research to explore the
                beauty of the Divine’s message.
              </p>
              <p className="col-2-text">
                She aims to share the linguistic beauty of Al-Quran with
                Aesthetically Illustrated concepts, accessible worldwide in a
                way that is innovative and approachable.
              </p>
            </div>
            <br />
            <p className="our-mission">Our Mission</p>
            <p className="col-1-text-3">
              <span className="pe-2">
                <ImQuotesLeft size={25} />
              </span>
              The DTD’s mission is to make the learning of the Quranic Arabic
              and the linguistic study of the Quran accessible worldwide in a
              way that is innovative, accessible, and approachable.
              <span className="ps-2">
                <ImQuotesRight size={25} />
              </span>
            </p>
          </Col>

          <Col
            lg={6}
            md={8}
            sm={12}
            xs={12}
            className="mt-3 col-2 ps-4 desktopViewAbout"
          >
            <p className="col-2-text">
              Direction to Divine is the reality of the dream seen by Ma’am
              Zille Huma in 2017. Mam Zille Huma has done Master’s in English
              Literature from the University of Punjab, Pakistan and studied
              linguistics as a subject in it. Her pursuit of Islamic knowledge
              started when she began to attend the Arabic linguistic lecture
              series by Ustadh Nouman Ali khan in 2014 and various other
              scholars.
            </p>
            <p className="col-2-text">
              She is doing an Arabic language programme by Bayyinah Institute
              and further studying Lughat-ul-Arabia. She has done Higher
              Academic Studies in Shariah Sciences, in which she studied
              multiple courses that include Aqeedah, Usool-e-Fiqh,
              Usool-e-Tafseer, Usool-e-Hadith, Amsaal-ul-Quraan, Arabic grammar
              etc. She began to do linguistic research to explore the beauty of
              the Divine’s message.
            </p>
            <p className="col-2-text">
              She aims to share the linguistic beauty of Al-Quran with
              Aesthetically Illustrated concepts, accessible worldwide in a way
              that is innovative and approachable.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutInitiative;
