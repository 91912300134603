import React from "react";
import { withRouter } from "../../Utils/withRouter";
import "./Admission.css";

function Admission({ navigate }) {
  return (
    <div className="admission_Container">
      <div className="admission_title">Registration</div>
      <div className="admission_open">Open</div>
      <div className="admission_subtitle">Classes starting soon</div>
      <div className="admission_button text-center">
        <button onClick={() => navigate("/Registration")}>Apply Now</button>
      </div>
    </div>
  );
}

export default withRouter(Admission);
