import React, { useState, useEffect, useCallback } from "react";
import ServerActions from "../../API/API";
import { withRouter } from "../../Utils/withRouter";
import male_img from "../../assets/images/male.jpg";
import { FaBook, FaEdit } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const UserPreview = (props) => {
  const { params } = props;
  const [user, setUser] = useState({});
  const [verificationStatus, setVerificationStatus] = useState(false);

  const fetch_user = useCallback(async () => {
    await ServerActions.get_register_by_id(params.id)
      .then((res) => {
        if (res && res.data !== "error") setUser(res.data);
      })
      .catch((err) => console.log(err));
  }, [params.id]);

  useEffect(() => {
    fetch_user();
  }, [fetch_user]);

  const openVerificationModal = () => {
    setVerificationStatus(true);
  };

  const handleVerificationStatus = async (state) => {
    await ServerActions.update_register_by_id(user._id, {
      approved: state,
    })
      .then((res) => {
        fetch_user();
        setVerificationStatus(false);
        if (res?.data === "success") {
          toast.success("Update successfully");
        } else {
          toast.error("unable to update");
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <br />
      <br />
      <br />
      <Modal
        show={verificationStatus}
        onHide={() => setVerificationStatus(false)}
        centered
      >
        <Modal.Header closeButton>Change Verification status to:</Modal.Header>
        <Modal.Body>
          <Button
            variant="success"
            onClick={() => handleVerificationStatus(true)}
          >
            Verified
          </Button>
          &nbsp;
          <Button
            onClick={() => handleVerificationStatus(false)}
            variant="danger"
          >
            Un Verified
          </Button>
        </Modal.Body>
      </Modal>
      <section style={{ backgroundColor: "#eee" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src={male_img}
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 className="my-3">{user.full_name}</h5>
                  <p className="text-muted mb-4">{user.address}</p>
                  <div className="d-flex justify-content-center mb-2">
                    {user.approved ? (
                      <button type="button" className="btn btn-primary">
                        Verified
                      </button>
                    ) : (
                      <button type="button" className="btn btn-danger">
                        Un Verified
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn ms-1"
                      onClick={() => openVerificationModal()}
                    >
                      <FaEdit size={15} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="card mb-4 mb-lg-0">
                <div className="card-body p-0">
                  <h4 className="text-center text-warning mt-2">
                    Enrolled in courses
                  </h4>
                  <ul className="list-group list-group-flush rounded-3">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <FaBook size={20} className="text-warning" />
                      <p className="mb-0">{user.course}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{user.full_name}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{user.email}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{user.phone}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Whats App</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{user.whats_app}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Address</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {user.address}, {user.city}, {user.country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(UserPreview);
