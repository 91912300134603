import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BooksCard from "../../Component/Card/CourseCard";
import { BsSearch } from "react-icons/bs";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import book_bg from "../../assets/images/Book_page_laptop.JPG";
import BooksAPI from "../../API/Books";
import "./Book.css";
import GetServerUrl from "../../API/GetServerUrl";

const Courses = () => {

  const [allBooks, setAllBooks] = useState([]);

  useEffect(() => {
    getAllBooks();
  }, []);

  const getAllBooks = async () => {
    await BooksAPI.get()
      .then(res => {
        if(res && res.data && Array.isArray(res.data)){
          setAllBooks(res.data);
        }
      }).catch(err => {
      })
  }

  const style = {
    searchBox: {
      width: "100%",
      border: "1px solid #aaa",
      display: "flex",
      padding: "5px 0px",
      borderRadius: "8px",
    },
    input: {
      width: "100%",
      height: "30px",
      border: "none",
      margin: "0px 10px",
    },
  };
  return (
    <>
      <div className="book_container">
        <img
          src={book_bg}
          className="w-100"
          loading="lazy"
          alt="directiontodivine Book Bg"
        />
      </div>
      <Container fluid>
        <div className="pt-5">
          <h1 className="text-primary text-center">Search for Books</h1>
          <div className="text-center">
            <Row className="m-3">
              <Col xl={8} lg={8} md={12} sm={12} xs={12} className="mx-auto">
                <div style={style.searchBox} className="search-box">
                  <BsSearch size={25} className="icon"></BsSearch>
                  <input style={style.input} placeholder="Search..." />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Container className="mb-5">
          <Row>
            <h4 className="text-center">
              or<b> Browse</b> Categories
            </h4>
          </Row>
          <Row className="my-5">
            {allBooks.map((el) => {
              return (
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <BooksCard
                    route={`/BookDetails/${el._id}`}
                    img={GetServerUrl() + "/images/" + el.img}
                    title={el.name}
                    discription={el.discription}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <div className="AyatContainer">
        <Container>
          <div className="trapezoid">
            <div className="trapzoid_text p-3">
              <p className="Ayat pt-4 px-2 text-center">
                <span className="pe-3">
                  <ImQuotesLeft className="quotes" />
                </span>
                Proclaim! (or read!) in the name of thy Lord and Cherisher, Who
                created – Created man, out of a (mere) clot of congealed blood:
                Proclaim! And thy Lord is Most Bountiful, He Who taught (the use
                of) the pen, Taught man that which he knew not
                <span className="ps-3">
                  <ImQuotesRight className="quotes" />
                </span>
              </p>
              <p className="Ayat text-center">[Quran, 96:1-5].</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Courses;
