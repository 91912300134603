import React from "react";
import { FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Sidebar = (props) => {
  return (
    <div className="admin_side_bar shadow">
      <div className="side_bar_close side_bar_toggler">
        <FaTimes size={20} onClick={props.toggleSideBar} />
      </div>
      <ul>
        <li>
          <NavLink
            to="/Admin/Home"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Admin/CoursesImages"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Manage Images
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Admin/ManageCourses"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Manage Courses
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Admin/ManageBooks"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Manage Books
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Admin/ManageRegisterations"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Manage Registrations
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Admin/ManageAccounts"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Manage Accounts
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Admin/ManageDonations"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Manage Donations
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Admin/FeeApplications"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Fee Concession Applications
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Logout"
            className={({ isActive }) => (isActive ? "active" : null)}
          >
            Logout
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
