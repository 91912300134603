import React, { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Data from "../../Data";
import "./Style.css";

const FeeConcessionForm = () => {
  const [validated, setValidated] = useState(false);
  const [application, setApplication] = useState({});
  const [allCourses, setAllCourses] = useState([]);

  useEffect(() => {
    setAllCourses(Data.Course);
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
    }
  };

  const handleInputs = (e) => {
    const name = e.target.name,
      value = e.target.value;
    if (name === "course") {
      setApplication({ ...application, [name]: allCourses[value] });
    } else {
      setApplication({ ...application, [name]: value });
    }
  };

  return (
    <Container>
      <br />
      <br />
      <br />
      <div className="shadow registration_container my-5">
        <h1 className="text-primary text-center ">
          Fee Concession Application
        </h1>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control name="full_name" onChange={handleInputs} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control name="email" onChange={handleInputs} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gender</Form.Label>
            <Form.Select name="gender" onChange={handleInputs} required>
              <option value="Male"> Male </option>
              <option value="Female"> Female </option>
            </Form.Select>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Education</Form.Label>
            <Form.Check
              type="radio"
              name="education"
              value="Bachelor's Degree"
              label="Bachelor's Degree"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="education"
              value="Master's Degree"
              label="Master's Degree"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="education"
              value="Aalim Course"
              label="Aalim Course"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="education"
              value="Other"
              label={
                <Form.Control
                  name="education"
                  onChange={handleInputs}
                  placeholder="Other"
                  required
                />
              }
              onChange={handleInputs}
              required
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Employment</Form.Label>
            <Form.Check
              type="radio"
              name="employment"
              value="Employed Full-Time"
              label="Employed Full-Time"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="employment"
              value="Employed Part-Time"
              label="Employed Part-Time"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="employment"
              value="Self-employed"
              label="Self-employed"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="employment"
              value="Unemployed"
              label="Unemployed"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="employment"
              value="Housewife"
              label="Housewife"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="employment"
              value="Student"
              label="Student"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="employment"
              value="Imam"
              label="Imam"
              onChange={handleInputs}
              required
            />
            <Form.Check
              type="radio"
              name="employment"
              value="Other"
              label={
                <Form.Control
                  name="employment"
                  onChange={handleInputs}
                  placeholder="Other"
                  required
                />
              }
              onChange={handleInputs}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Annual Income</Form.Label>
            <Form.Control
              name="annual_income"
              onChange={handleInputs}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>City</Form.Label>
            <Form.Control name="city" onChange={handleInputs} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Country</Form.Label>
            <Form.Control name="country" onChange={handleInputs} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Select Course</Form.Label>
            <Form.Select name="course" onChange={handleInputs} required>
              <option value=""></option>
              {allCourses.map(
                (el, index) =>
                  el.is_admission_open && (
                    <option key={el.id} value={index}>
                      {el.name}
                    </option>
                  )
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="react_phoneInput">
            <Form.Label>Phone Number</Form.Label>
            <PhoneInput
              country={"pk"}
              onChange={(e) =>
                handleInputs({ target: { value: e, name: "phone" } })
              }
              enableSearch={true}
              inputProps={{ required: true }}
              placeholder="+92"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Why are you applying for Al Balagh Scholarship?
            </Form.Label>
            <Form.Control
              as="textarea"
              name="reason_to_apply"
              onChange={handleInputs}
              rows="6"
              style={{ resize: "none" }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              How will taking this course help you achieve your goals and how
              this would be beneficial for society?
            </Form.Label>
            <Form.Control
              as="textarea"
              name="course_help_for_goal"
              onChange={handleInputs}
              rows="6"
              style={{ resize: "none" }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>How much fee can you afford to pay?</Form.Label>
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="90%"
              label="90%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="80%"
              label="80%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="70%"
              label="70%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="60%"
              label="60%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="50%"
              label="50%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="40%"
              label="40%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="30%"
              label="30%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="20%"
              label="20%"
              onChange={handleInputs}
            />
            <Form.Check
              type="radio"
              name="affordabel_payment"
              value="other"
              label={
                <Form.Control
                  name="affordabel_payment"
                  onChange={handleInputs}
                  placeholder="Other"
                />
              }
              onChange={handleInputs}
            />
          </Form.Group>
          <br />
          <div className="text-center">
            <Button> Submit Application </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default FeeConcessionForm;
