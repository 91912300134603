import React, { useState } from "react";
import "./Donations.css";
import Button from "react-bootstrap/Button";
import { Container, Form } from "react-bootstrap";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import pic from "../../assets/images/DonationImg.png";
import PhoneInput from "react-phone-input-2";
import ServerActions from "../../API/API";

export default function Donations() {
  const [donation, setDonation] = useState({
    name: "",
    email: "",
    phone: "",
    amount: "",
    company: "",
  });

  const [validated, setValidated] = useState(false);

  const handleInputs = (e) => {
    const name = e.target.name,
      value = e.target.value;
    setDonation({ ...donation, [name]: value });
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      await ServerActions.post_donation(donation)
        .then((res) => {
          console.log(res.data);
          if (res && res.data === "success") {
            let { name, amount, email, phone, company } = donation;
            let url =
              `https://api.whatsapp.com/send?phone=923205486567&&text=*Asslam O Alaikum!*%0a` +
              `I am *${name}*. I want to donate *${amount}* rupees to DTD program.%0a` +
              `My other details are: %0a` +
              `Email: *${email}*%0a` +
              `Phone: *${phone}*%0a` +
              `company: *${company}*%0a`;
            window.open(url);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="bg-secondary">
        <div className="donation_image">
          <img
            src={pic}
            alt="directiontodivine donation"
            width={"100%"}
          />
        </div>
        <div className="donation-wrapper py-5">
          <div className="donationContainer mx-auto pt-5 box_shadow ">
            <h1 className="text-primary">Make a Donation</h1>
            <p className="donation_para">
              Every donation, no matter how big or small, makes a significant
              difference to our cause. Thank you for doing your part to help
            </p>
            <div className="donator_info">
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleInputs}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleInputs}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="react_phoneInput">
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneInput
                    country={"pk"}
                    onChange={(e) =>
                      handleInputs({ target: { value: e, name: "phone" } })
                    }
                    enableSearch={true}
                    inputProps={{ required: true }}
                    placeholder="+92"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    name="amount"
                    min={10}
                    onChange={handleInputs}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Required or donate minimum 10 rupees
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    onChange={handleInputs}
                    placeholder="if applicable"
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Button type="submit" className="w-100" variant="success">
                    Submit Donation
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="AyatContainer">
        <Container>
          <div className="trapezoid">
            <div className="trapzoid_text p-3">
              <p className="Ayat pt-4 px-2 text-center">
                <span className="pe-3">
                  <ImQuotesLeft className="quotes" />
                </span>
                Prophet Muhammad (peace be upon him) said, “The believer’s shade
                on the Day of Resurrection will be his charity.
                <span className="ps-3">
                  <ImQuotesRight className="quotes" />
                </span>
              </p>
              <p className="Ayat text-center">(Al-Tirmidhi)</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
