import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes } from "react-router";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import WhatsAppButton from "./Component/Buttons/WhatsAppButton";
import { withRouter } from "./Utils/withRouter";
import { ToastContainer } from "react-toastify";
import * as Pages from "./Pages/index";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "./scss/index.css";
import "./scss/index.css.map";
import axios from "axios";
import localStorageManager from "./Utils/localStorageManager";
// import 'bootstrap/dist/css/bootstrap.min.css';

const App = ({ location, ...props }) => {

  useLayoutEffect(() => {
    axios.interceptors.request.use(
      config => {
        const token = localStorageManager.getToken();
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      error => {
        Promise.reject(error)
      });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <ToastContainer />
      {!location.pathname.toLowerCase().includes("admin") && (
        <>
          <WhatsAppButton />
          <Header />
        </>
      )}
      <Routes>
        <Route path="/" index element={<Pages.Home />} />
        <Route path="/BookDetails/:id" element={<Pages.BooksDetail />} />
        <Route path="/CourseDetails/:id" element={<Pages.CourseDetails />} />
        <Route path="/user/:id" element={<Pages.UserPreview />} />
        <Route path="/SeerahDetails/:id" element={<Pages.SeerahDetails />} />
        <Route path="/Books" element={<Pages.Books />} />
        <Route path="/Seerah" element={<Pages.Seerah />} />
        <Route path="/Courses" element={<Pages.Courses />} />
        <Route path="/Donations" element={<Pages.Donations />} />
        <Route path="/Registration" element={<Pages.Registration />} />
        <Route path="/Admin/Login" element={<Pages.Login />} />
        <Route path="/Admin" element={<Pages.AdminRoutes.Admin />} />
        <Route path="/Admin/*" element={<Pages.AdminRoutes.Admin />} />
        <Route path="/Logout" element={<Pages.AdminRoutes.Logout />} />
        <Route path="/FeeApplications" element={<Pages.FeeConcessionForm />} />
        <Route path="*" element={<Pages.ErrorPage />} />
      </Routes>

      <Footer />
    </>
  );
};
export default withRouter(App);
