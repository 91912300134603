import React, { useEffect, useState } from "react";
import AdmissionContainer from "../../../Component/Admission/Admission";
import { Col, Container, Row, Image, Button, Modal } from "react-bootstrap";
import photo from "../../../assets/images/dummydesigner.png";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { withRouter } from "../../../Utils/withRouter";
import Courses from "../../../API/Courses";
import GetServerUrl from "../../../API/GetServerUrl";
import { useNavigate } from "react-router";
import "../Courses.css";
import "../../../Component/TeamSlider/TeamSlider.css";

const style = {
  imgStyle: {
    width: "100%",
    // height: "100%",
    objectFit: "contain",
    objectPosition: "center",
  },

  joinBtnWidth: {
    display: "block",
    backgroundColor: "var(--bs-primary)",
    color: "white",
    textDecoration: "none",
    width: "60%",
    padding: "10px",
    textAlign: "center",
  },
};

const CourseDetails = (props) => {
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  const [proceedModal, setProcessModal] = useState(false);

  useEffect(() => {
    const getCourse = async () => {
      await Courses.getById(props.params.id).then((res) => {
        if (res && res.data) {
          setCourse(res.data);
        }
      });
    };
    getCourse();
  }, [props.params.id]);

  const toggleProceedModal = () => setProcessModal(!proceedModal);


  return (
    <>
      <br />
      <br />
      <br />
      <br />
      {course.isAdminssionOpen && <AdmissionContainer />}
      <Modal show={proceedModal} onHide={toggleProceedModal} centered>
        <Modal.Header>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you are pakistan national then your fee for Quran's Language
          Program is 1500 PKR per month. For international students it's 4000
          PKR per month.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={toggleProceedModal}>
            Cancel
          </Button>
          &nbsp;
          <Button variant="primary" onClick={() => navigate("/Registration")}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="p-5 course_detail_container">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <Image
              src={
                course?.courseImg
                  ? GetServerUrl() + "/images/" + course.courseImg
                  : photo
              }
              loading="lazy"
              thumbnail
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="p-2">
            <div className="course_detail">
              <h1 className="text-primary">{course?.name}</h1>
              <p
                className="w-100 detail_text"
                dangerouslySetInnerHTML={{ __html: course?.discription }}
              ></p>
            </div>
          </Col>
        </Row>
        {course?.explaination ? (
          <Row>
            <div className="mb-2">
              <h1 className="text-primary">Explanation</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: course.explaination }} />
          </Row>
        ) : null}
        {course?.features ? (
          <Row>
            <div className="mb-2">
              <h1 className="text-primary">Features</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: course.features }} />
          </Row>
        ) : null}
        {course?.courseStructure ? (
          <div className="container">
            <h1 className="text-primary">Course Structure</h1>
            <br />
            <div dangerouslySetInnerHTML={{ __html: course.courseStructure }} />
          </div>
        ) : null}
        {course?.atAGlance && (
          <>
            <div className="my-5">
              <h1 className="text-primary">At a glance</h1>
              <div dangerouslySetInnerHTML={{ __html: course.atAGlance }} />
            </div>
          </>
        )}
        {course?.courseFeeLine ? (
          <div className="py-5">
            <h1 className="text-primary"> Course Fee: </h1>
            <p className="px-3">
              <div dangerouslySetInnerHTML={{ __html: course.courseFeeLine }} />
            </p>
          </div>
        ) : null}

        {course?.youtubeLinks ? (
          <div className="py-5">
            {course.youtubeLinks.map((el) => (
              <iframe
                src={el}
                className="w-100"
                title="Youtube Video"
                style={{ minHeight: "400px" }}
              />
            ))}
          </div>
        ) : null}
        <div className="py-5">
          <h1 className="text-center text-primary py-3">Instructor</h1>
          <div className="teamCard my-3 box_shadow mx-auto">
            <div className="bgLightArea"></div>
            <div className="bgGreyArea"></div>
            <div className="teamBio">
              <div className="teamImgBorder text-center">
                <img
                  src={GetServerUrl() + "/images/" + course?.instructorImage}
                  style={{ objectPosition: "top" }}
                  alt="team member"
                />
              </div>
              <div className="pt-1 teamInfo">
                <p className="TeamName text-center"> {course?.instructor} </p>
              </div>
              <div className="TeamDiscription mt-3">
                <p dangerouslySetInnerHTML={{__html: course?.instructorDisc || ""}}></p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center my-3">
          <Button
            style={style.joinBtnWidth}
            onClick={() => {
              if (course?.joinNowYoutubeLink) {
                window.open(course.joinNowYoutubeLink);
                return;
              }

              if (course?.courseFeeLine) {
                toggleProceedModal();
                return;
              }
              navigate("/Registration");
            }}
          >
            Join this course
          </Button>
        </div>
      </Container>
      <div className="AyatContainer">
        <Container>
          <div className="trapezoid">
            <div className="trapzoid_text p-3">
              <p className="Ayat pt-4 px-2 text-center">
                <span className="pe-3">
                  <ImQuotesLeft className="quotes" />
                </span>
                Abu Hurairah reported the Prophet (ﷺ) as saying: <br />
                If anyone pursues a path in search of knowledge, Allah will then
                by make easy for him a path to paradise; and he who is made slow
                by his actions will not be speeded by his genealogy.
                <span className="ps-3">
                  <ImQuotesRight className="quotes" />
                </span>
              </p>
              <p className="Ayat text-center">Sunan Abi Dawud 3643</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(CourseDetails);
