import React, { useEffect, useState, useRef } from "react";
import logo from "../../assets/images/Logo-Divine-White.png";
import logo2 from "../../assets/images/LOGO_green.png";
import { Image } from "react-bootstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { withRouter } from "../../Utils/withRouter";
import "./Header.css";

const Header = (props) => {
  const [headerBackgroundColor, setColor] = useState("transparent");
  const sideBar = useRef(null);
  const desktopBar = useRef(null);

  useEffect(() => {
    changeColor();

    const desktopBarStyle = getComputedStyle(desktopBar.current);

    if (desktopBarStyle.display === "none") {
      closeSideBar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  const changeColor = () => {
    if (
      props.location.pathname === "/" ||
      props.location.pathname === "/Courses" ||
      props.location.pathname === "/Seerah" ||
      props.location.pathname === "/Donations" ||
      props.location.pathname === "/Books"
    ) {
      if (headerBackgroundColor !== "transparent") setColor("transparent");
    } else {
      if (headerBackgroundColor !== "white") setColor("white");
    }
  };

  const closeSideBar = () => {
    sideBar.current.style.width = "0px";
  };

  const openSideBar = () => {
    sideBar.current.style.width = "250px";
  };

  return (
    <>
      <div className="header">
        <div className="logo">
          <Link to="/">
            <Image
              src={headerBackgroundColor === "transparent" ? logo : logo2}
              alt="directiontodivine logo"
            />
          </Link>
        </div>
        <div className="desktopBar" ref={desktopBar}>
          <div className="navbar">
            <ul>
              {routes.map((el) => {
                return (
                  <li key={el.path}>
                    <Link
                      to={el.path}
                      style={{
                        color: `${
                          headerBackgroundColor === "transparent"
                            ? "white"
                            : "var(--bs-primary)"
                        }`,
                      }}
                    >
                      {el.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="mobileBar">
          <button
            className="toggleBarIcon"
            style={{
              color: `${
                headerBackgroundColor === "transparent"
                  ? "var(--bs-primary)"
                  : "white"
              }`,
              backgroundColor: `${
                headerBackgroundColor === "transparent"
                  ? "white"
                  : "var(--bs-primary)"
              }`,
            }}
            onClick={openSideBar}
          >
            <FaBars size="20px" />
          </button>
          <div className="sideBar" ref={sideBar} style={{ width: "0px" }}>
            <div className="btnClose">
              <button onClick={closeSideBar}>
                <FaTimes />
              </button>
            </div>
            <div>
              {routes.map((el) => {
                return (
                  <li key={el.path}>
                    <Link to={el.path}>{el.name}</Link>
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Header);

const routes = [
  { name: "Home", path: "/" },
  { name: "Books", path: "/Books" },
  { name: "Courses", path: "/Courses" },
  // {name: "Seerah", path: "/Seerah"},
  // {name: "Blog", path: "/Blog"},
  { name: "Donate", path: "/Donations" },
  { name: "Register", path: "/Registration" },
];
