import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdminAPI from '../../API/Admin';
import localStorageManager from '../../Utils/localStorageManager';
import { withRouter } from '../../Utils/withRouter';

const Login = ({ navigate, ...props }) => {

    const [admin, setAdmin] = useState({
        email: "",
        password: "",
    })

    const handleInputs = (e) => {
        let name = e.target.name, value = e.target.value;
        setAdmin({ ...admin, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (admin.email && admin.password) {
            await AdminAPI.login(admin)
                .then(res => {
                    if (res && res.data && res.data.token) {
                        localStorageManager.setUser(res.data);
                        localStorageManager.setToken(res.data.token);
                        navigate("/admin/home");
                    }
                    else {
                        toast.warn("Email and password mismatched");
                    }
                })
                .catch(err=>{
                    toast.error("Email and password mismatched");
                })

        } else {
            toast.warn("Please check fields");
        }
    }


    return (

        <Container className='d-flex justify-content-center align-items-center' style={{ height: "100vh" }}>
            <Form onSubmit={handleSubmit}>
                <div className="shadow p-5 p-3">
                    <Form.Group>
                        <Form.Label>
                            Email
                        </Form.Label>
                        <Form.Control name="email" type="email" onChange={handleInputs} value={admin.email} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Password
                        </Form.Label>
                        <Form.Control name="password" type="password" onChange={handleInputs} value={admin.password} />
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Button type="submit" className='w-100'>
                            Login
                        </Button>
                    </Form.Group>
                </div>
            </Form>
        </Container>
    )
}

export default withRouter(Login)
