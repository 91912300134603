import course1_img from "./assets/images/course1.jpeg";
import course2_img from "./assets/images/course2.jpeg";
import course1_tutor from "./assets/images/course1_tutor.jpeg";
import book1_img from "./assets/images/Book1.jpeg";
import orientation_img from "./assets/images/orientation.jpeg";
import course_2_img from "./assets/images/course_2_img.jpeg";
const Data = {
  Course: [
    {
      id: "DreamIntensiveCourse",
      name: "Dream Arabic Intensive",
      discription: `
                Dream Intensive is a Quranic Applicative Arabic course. <br />
                This course is basically for those who are new to Arabic and want to learn the basics 
                <br />
                (A solid foundation to learn Quranic Arabic language). 
                <br /> 
                In this course, we dive deep into the grammar and linguistics of Quranic Arabic so that we can have a better 
                approach to the versatility of the Quran.`,
      course_tagline: "Start Your Journey Through The Quran’s Language",
      course_img: course1_img,
      tutor: "Ma'am Zill e Huma",
      tutor_dics: `Zillehuma Founder and Director of Direction to Divine studied shariyah sciences and student of "Alimiyyah"`,
      tutor_img: course1_tutor,
      features: [
        "This course is divided into four intensives",
        "First and second intensives are on NAHW study",
        "Third and fourth intensives are on SARF study",
        "80 days course",
        "Language: English and Urdu",
        "Mode: Video recorded lectures on YouTube",
      ],
      what_you_will_learn: `Students will get deep applied knowledge of Arabic learning. They will be able to understand the
                original Arabic language of the Quran.`,
      youtube_link: "https://youtu.be/_umr007a5BQ",
      is_paid: false,
      orientation_img,
    },
    {
      id: "QuransLanguage",
      name: "Quran's Language Program",
      discription: `
            Learn the foundational concepts pertaining to Quranic Arabic and gain a 
            deeper appreciation of the Book that Allah SWT sent down as mercy and 
            guide to all of humanity. <br />
            Language of Quran is a unique program designed to meet the interest of Muslims in learning Arabic 
            in order to develop sufficient language competence to acquire Islamic knowledge from original 
            Arabic primary texts. understand that materializing this course is a step by step process and we hope 
            to share with you how we can together be part of making this dream a reality.
            <br />
            Students begin their journey with the study of foundational Arabic grammar and morphology. 
            Throughout the course, they build the skills necessary to read and understand the Qur’an as well as 
            classical Islamic texts in Arabic. It is our goal to connect students to the Quran, sacred knowledge, 
            and the Islamic tradition through the study of the Arabic language while remaining focused on two 
            main proficiencies: <br/ >
            1: To understand the Quran without a translation. <br />
            2: To read and translate classical Arabic text <br />`,
      course_tagline: "Start Your Journey Through The Quran’s Language",
      course_img: course2_img,
      tutor: "Ma'am Zill e Huma",
      tutor_dics: `Zillehuma Founder and Director of Direction to Divine studied shariyah sciences and student of "Alimiyyah"`,
      tutor_img: course1_tutor,
      what_you_will_learn: `Students will get deep applied knowledge of Arabic learning. They will be able to understand the
            original Arabic language of the Quran.`,
      course_structure: [
        {
          heading: "LEVEL ONE NAHW: ",
          disc: `The science of Arabic grammar (nahw) is the oldest and one of the most important of all 
                    the Islamic sciences. It began in the time of the Companions, when the mistakes of nonArabian converts to Islam began adulterating the linguistic purity of the ancient Arabic 
                    language. The early grammarians preserved this ancient language through formal rules, 
                    thereby preserving our ability to understand the Qur’an and Sunna. In this level we will 
                    apply the most foundational knowledge of Arabic grammar. You will master the 
                    grammatical characteristics of nouns, verbs, prepositions, fragmented phrases and basic 
                    complete sentences directly in the Quran.`,
        },
        {
          heading: "LEVEL TWO SARF: ",
          disc: `Sarf is the study of morphology. That’s just a fancy way of saying word structure, word 
                    patterns, and how words are formed!. That means it is the study of how the spellings of 
                    words impact what they mean in Arabic. In this unit students gain foundational knowledge 
                    of the system of Arabic morphology as it is broken into verb patterns and families. This 
                    knowledge will then help students acquire vocabulary at an exponentially faster rate and 
                    also sharpen their ability to decipher meanings of individual words based on their spelling 
                    patterns`,
        },
        {
          heading: "LEVEL THREE ADVANCED SARF:",
          disc: `This unit consists of the study of irregular verb and noun conjugations, the implications of 
                    complex conjugations and the meanings behind the patterns of Arabic spellings`,
        },
        {
          heading: "LEVEL FOUR ADVANCED NAHW:",
          disc: `This unit includes the study of advanced sentence structure, complex fragments, issues in 
                    translation, multiple grammatical ways of looking at the same ayaat and the impact of each 
                    on translation and the science of I’rab (grammatical analysis).`,
        },
        {
          heading: "LEVEL FIVE RHETORIC (BALAGHAH) :",
          disc: `Rhetoric means balaghah. It is the study of the powerful tools in the language that a speaker 
                    uses to have deep impact on his or her listeners. This unit utilizes unique method of teaching 
                    the classical science of Arabic rhetoric in an applied fashion. Examples used are either every 
                    day speech or Qur’an. Students gain a literary and linguistic appreciation of the Qur’an’s 
                    artistic style`,
        },
      ],
      middle_img: course_2_img,
      unlock_quran_essentials: {
        name: "UNLOCK QURAN ESENTIALS",
        disc: `UnlockQuran essentials is a course specifically for students that have learned Arabic 
                Grammar in this course and would like to master their knowledge.This mastery is gained by 
                direct application in the Quran. This Level is the fruit of all previous labor. It is a walkthrough of 
                surat AL-BAQARAH analyzing all of the nahw, sarf and balaghah previously studied so students have 
                deep applied knowledge of their Arabic learning through the largest surah of the Qur’an. Explore this 
                surah in a way you’ve never thought possible.Discover numerous gems as you perform an 
                in-depth grammatical analysis of the verses of this surah, all the while applying all the 
                grammar that we’ve covered in UnlockQuran Essentials. ( It’s a separate course ).
                `,
      },
      at_a_glance: [
        "Completely Online",
        "Flexible",
        "Classes ( Monday, Wednesday, Friday)",
        "Q/A Session ( On weekends )",
        "Lecture Format ( PowerPoint Presentations ) ",
        "Vocabulary Learning Through Animated Videos",
        "Quiz Games And Weekly Assessments",
        "Illustrated Posters Of Deep Concepts",
        "Zoom Recorded Sessions On YouTube",
        "WhatsApp Student Support groups",
        "Charges Apply Monthly",
        "QURAN’S LANGUAGE PROGRAME Duration ( 3 years ) ",
        "UNLOCK QURAN ESSENTIALS Duration ( 1.5 year)",
        "Certified Course",
      ],
      youtube_links: ["https://youtube.com/embed/Crywxjp6ZpI"],
      is_paid: true,
      course_fee: "1500 PKR",
      is_admission_open: false,
    },
  ],
  Book: [
    {
      id: "Book1",
      name: "Fatihah-tul-kitaab",
      discription:
        "Fatiha Tul Kitab will help you to understand the Surah Fatiha to the root level. Linguistic analysis aided by illustration will not only make the understanding easy for you but it will stay in your memory for long In Sha Allah. You will clearly feel a different essence of deep understanding of surah fatiha in this book.",
      img: book1_img,
      book_url: "/books/Fatiha-tul kitab.pdf",
      youtube_links: [
        "https://www.youtube.com/embed/E1pQAkGY_v4",
        "https://www.youtube.com/embed/jCU0pNsh9ks",
      ],
      book_prize: "500 PKR",
    },
  ],
};

export default Data;
