import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const Logout = () => {
  const [frame, setFrame] = useState(false);
  useEffect(() => {
    localStorage.clear();
    setFrame(true);
  }, []);
  return frame && <Navigate to="/Admin/Login" />;
};

export default Logout;
