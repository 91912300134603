import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../Utils/withRouter";
import "./HomeContainer.css";

const HomeContainer = (props) => {
  return (
    <>
      <div className="main-sec pt-5">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="content">
          <div className="paragraph">
            <h1>
              Let's explore the linguistic beauty of the Divine's&nbsp;message, Al-Kitab
            </h1>
            <Link to="/Courses">Explore</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(HomeContainer);
