import React from "react";
import {
  Col,
  Container,
  Row,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FaFacebookSquare,
  FaWhatsapp,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import logo2 from "../../assets/images/DTD_With_Glow.png";
import { Link } from "react-router-dom";
import { withRouter } from "../../Utils/withRouter";
// import logo from '../../assets/images/Logo-Divine-White.png';

const Footer = ({ navigate }) => {
  return (
    <>
      <Container fluid className="bg-secondary pb-3 pt-5">
        <Row className="p-3">
          <Col md={4} xs={12} className="my-3">
            <Link to="/">
              <Image
                src={logo2}
                rounded
                height={100}
                alt="directiontodivine logo"
              />
            </Link>
            <div className="footerTextUnderLogo mt-5">
              <p>
                With a transformational and ambitious vision to be the Global
                Arabic linguistic study platform that provides universal access
                to the world’s best education of Classical Arabic, the
                linguistic beauty of the Quran and the studying of the prophetic
                journey in-depth with graphical presentation.
              </p>
            </div>
          </Col>

          <Col md={4} xs={12} className=" footerLinks my-3">
            <Row>
              <Col sm={2} xs={3} className="my-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="mt-3 tiny" id="tooltip-bottom">
                      Goto facebook page
                    </Tooltip>
                  }
                >
                  <a
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/directiontodivine/"
                    target="_blank"
                    className="text-light mx-3"
                  >
                    <FaFacebookSquare size={35} />
                  </a>
                </OverlayTrigger>
              </Col>
              <Col sm={2} xs={3} className="my-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="mt-3 tiny" id="tooltip-bottom">
                      Chat on whatsapp
                    </Tooltip>
                  }
                >
                  <a
                    rel="noopener noreferrer"
                    href="https://wa.me/03364414208"
                    className="text-light mx-3"
                  >
                    <FaWhatsapp size={35} />
                  </a>
                </OverlayTrigger>
              </Col>
              <Col sm={2} xs={3} className="my-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="mt-3 tiny" id="tooltip-bottom">
                      Mail us
                    </Tooltip>
                  }
                >
                  <a
                    rel="noopener noreferrer"
                    href="mailto:directiontodivine@gmail.com"
                    target="_blank"
                    className="text-light mx-3"
                  >
                    <AiOutlineMail size={35} />
                  </a>
                </OverlayTrigger>
              </Col>
              <Col sm={2} xs={3} className="my-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="mt-3 tiny" id="tooltip-bottom">
                      Subscrib on youtube
                    </Tooltip>
                  }
                >
                  <a
                    rel="noopener noreferrer"
                    href="https://youtube.com/c/DirectiontoDivine"
                    target="_blank"
                    className="text-light mx-3"
                  >
                    <FaYoutube size={35} />
                  </a>
                </OverlayTrigger>
              </Col>
              <Col sm={2} xs={3} className="my-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="mt-3 tiny" id="tooltip-bottom">
                      Follow our insta
                    </Tooltip>
                  }
                >
                  <a
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/directiontodivine/?utm_medium=copy_a"
                    target="_blank"
                    className="text-light mx-3"
                  >
                    <FaInstagram size={35} />
                  </a>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>

          <Col md={4} xs={12} className="text-light footerText my-3">
            <div>
              <h5 onClick={() => navigate("/Courses")}> Courses </h5>
              <p>Start Your Journey Through The Quran’s Language.</p>
            </div>
            <div>
              <h5 onClick={() => navigate("/Books")}> Books </h5>
              <p>
                Explore the linguistic beauty of the Quran and understand the
                divine's message.
              </p>
            </div>
            <div>
              <h5> Seerah </h5>
              {/* <h5 onClick={()=>navigate("/Seerah")}> Seerah </h5> */}
              <p>
                Gain a better understanding of the Prophetic life and explore
                the approaches in reviving the spirit of Islam through studying
                the Prophetic journey in-depth.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="w-100  d-flex justify-content-center developer__contact">
        <div className="developer__copyright">©️ 2022 Direction To Divine</div>
        <div className="developer__name">
          Design & Developed By
          <a
            href="https://wa.me/03013662885"
            rel="noopener noreferrer"
            target="__blank"
          >
            Muhammad Hammad
          </a>
          <a
            href="https://wa.me/03013662885"
            rel="noopener noreferrer"
            target="__blank"
          >
            <FaWhatsapp size={18} />
          </a>
          <a
            href="https://www.linkedin.com/in/muhammad-hammad-mansha-8ab0b21a1/"
            rel="noopener noreferrer"
            target="__blank"
          >
            <FaLinkedin size={18} />
          </a>
        </div>
      </div>
    </>
  );
};
export default withRouter(Footer);
